import Nav from 'react-bootstrap/Nav';
import Logo from '../components/Logo';
import SocialsNav from '../components/SocialsNav';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="container-fluid d-flex flex-column align-items-center">
        <div className="mb-3">
          <Logo variant="vertical" color="white" />
        </div>
        <Nav className="footer-nav">
          <Nav.Item>
            <Link to="/products" className="nav-link">Products</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/contact" className="nav-link">Contact</Link>
          </Nav.Item>
        </Nav>
        <SocialsNav />
        <div className="footer-copyright">
          <p className="mb-0 mt-3">©DealZone. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;