import axios from 'axios';
import config from '../config.json';
import { useState } from 'react';

import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Hero from '../containers/Hero';
import InvestReasonCard from '../components/InvestReasonCard';
import LandingBlock from '../components/LandingBlock';
import EmailBlock from '../components/EmailBlock';
import Button from 'react-bootstrap/Button';

import IconLessCompetition from '../img/blitz.svg';
import IconValuation from '../img/valuation.svg';
import Image1  from '../img/image1.png';
import Image2  from '../img/image2.png';
import Image3  from '../img/image3.png';
import Image4  from '../img/image4.png';
import Member1 from '../img/member1.png';
import Member2 from '../img/member2.png';
import Member3 from '../img/member3.png';
import Member4 from '../img/member4.png';
import IconBox from '../img/box.svg';
import IconTruck from '../img/truck.svg';
import Map from '../img/map.png';
import HeroBackground from '../img/hero-bg.png';

function Home(props) {
  const [totalUsers, setTotalUsers] = useState(0);

  const getTotalUsers = () => {
    axios.get(config.SERVER_URL + '/api/total-users').then(response => {
      setTotalUsers(response.data.total_users);
    })
    return 103+totalUsers;
  }

  return (
    <>
      <Hero
        headerComponent={ <Header variant="white" /> }
        title="Your trusted originator of small businesses and Lower Middle Market Companies"
        backgroundImage={ HeroBackground }
      >
        <p>DealZone uses AI to aggregate business listings from brokers and  investment banks across the US in the micro PE space <span>($1 – $30 M in revenues and $500K to $2.5M in SDE OR EBITDA)</span></p>
      </Hero>
      <div className="site-content">
        <section id="invest-reasons">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="section-header text-center">Why invest in small business?</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <InvestReasonCard
                  title="Less competition"
                  text="The small business and lower middle market is much  less efficient than the Middle Market."
                  icon={IconLessCompetition}
                />
              </div>
              <div className="col-lg-6 mb-4">
                <InvestReasonCard
                  title="Valuation"
                  text="Valuations often are 3x – 5x EBITDA in  this space."
                  icon={IconValuation}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="landing-blocks">
          <div className="container">
            <LandingBlock
              image={Image4}
              title="FAVORABLE STATISTICS: The Baby Boomer Wealth Transfer"
            >
              <p className="mb-0">
                <ul>
                  <li>
                    Retiring Boomer business owners will sell or bequeath $10 trillion worth of assets over the next two decades.
                  </li>
                  <li>
                    These assets are held in more than 12 million privately owned businesses.
                  </li>
                  <li>
                    More than 70 percent of these companies are expected to change hands.
                  </li>
                </ul>
                <br />
                <i style={{fontSize: '12px'}}>*Source: California Association of Business Brokers</i>
              </p>
            </LandingBlock>
            <LandingBlock
              image={Image2}
              contentSide="left"
            >
              <p className="mb-0 mt-3">
                72% of business owners with revenues from $250,000 to $20 million
                have NO EXIT PLAN and are taking no action to exit.
                <br/><br/>
                Most business owners will exit within a decade:
                <br/><br/>
                <ul>
                  <li>29% expect to exit within 5 years</li>
                  <li>54% anticipate being gone within 10 years</li>
                  <li>
                    If the research correct, 62% of 210,000 businesses changing hands
                    each year will be turned over by baby boomers to a new group of enterpreneurs.
                  </li>
                </ul>
                <br />
                <i style={{fontSize: '12px'}}>*Source: Securian Financial's Small Business Owner Life Stage Study</i>
              </p>
            </LandingBlock>
          </div>
        </section>
        <section id="statistics">
          <div className="landing-statistics-wrapper">
            <div className="container-fluid">
              <div className="landing-statistics">
                <div className="landing-statistics-item d-flex flex-column align-items-center">
                  <div className="landing-statistics-text">{getTotalUsers()} +</div>
                  <div className="landing-statistics-description">Worldwide Clients</div>
                </div>
                <div className="landing-statistics-spacer"></div>
                <div className="landing-statistics-item d-flex flex-column align-items-center">
                  <div className="landing-statistics-text">7,000 +</div>
                  <div className="landing-statistics-description">Successful Projects</div>
                </div>
                <div className="landing-statistics-spacer"></div>
                <div className="landing-statistics-item d-flex flex-column align-items-center">
                  <div className="landing-statistics-text">$500,000 +</div>
                  <div className="landing-statistics-description">Average Transactions</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="email">
          <EmailBlock />
        </section>
        <section id="contacts">
          <div className="container mb-5">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center mb-4">
                <p className="mb-4">
                  DealZone allows an M&A professional to spend more time analyzing, closing, and growing businesses and less time sourcing transactions. DealZone helps stop the fragmentation and wasted time pouring over emails and online listings by aggregating everything in an easy-to-use graphical user interface.
                </p>
                <Button href="/contact">Contact Us</Button>
              </div>
              <div className="col-12 col-lg-6">
                <div className="contacts-map-wrapper">
                  <img src={ Map } alt="Map" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Home;