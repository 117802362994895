import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { TableSortingButton } from '../utils';
import { useState } from 'react';

function DealsTable({
  data = [], allUsers, onSelectedChange = () => {}, sortConfig, onSort
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const selectAllChecked = allUsers.length > 0 && selectedUsers.length === allUsers.length;
  
  const updateSelectedUsers = users => {
    let newUsers = selectedUsers.slice();

    users.forEach(user => {
      if (user.selected && !selectedUsers.includes(user.id)) {
        newUsers.push(user.id);
      } else if (!user.selected && selectedUsers.includes(user.id)) {
        newUsers = newUsers.filter(d => d !== user.id);
      }
    });

    setSelectedUsers(newUsers);
    onSelectedChange(newUsers);
  }

  const handleCheckAll = event => {
    let checked = event.target.checked;
    let selectedUsers = allUsers.map(user => ({ id: user.id, selected: checked }));
    updateSelectedUsers(selectedUsers);
  }

  const handleEventCheck = (event, userId) => {
    let selected = !selectedUsers.includes(userId);
    let userToSelect = {
      id: userId,
      selected
    };

    if (selectAllChecked) {
      let unchacked = allUsers.map(user => ({ id: user.id, selected: false }));
      updateSelectedUsers([...unchacked, userToSelect]);
    } else {
      updateSelectedUsers([userToSelect]);
    }
  }

  const rows = data.map((user) => {
    let {
      Email,
      'Subscription Status': SubscriptionStatus,
    } = user;
    
    return (
      <tr
        key={user.id}
      >
        <td><Form.Check type="checkbox" checked={ selectedUsers.includes(user.id) } onChange={ (event) => handleEventCheck(event, user.id) } /></td>
        <td>{ Email }</td>
        <td>{ SubscriptionStatus }</td>
      </tr>
    );
  });

  return (
    <div className="table-responsive table-scroll-wrapper">
      <Table className="dashboard-table">
        <thead>
          <tr>
            <td>
              <Form.Check
                type="checkbox"
                onChange={ handleCheckAll }
                checked={ selectAllChecked }
              />
            </td>
            <td>Email <TableSortingButton fieldName="Email" onSort={ onSort } sortConfig={ sortConfig } /></td>
            <td>Subscription Status</td>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </Table>
    </div>
  );
}

export default DealsTable;