import IconHorizontal from '../img/logo-horizontal.svg';
import IconHorizontalBlue from '../img/logo-horizontal-blue.svg';
import IconVertical from '../img/logo-vertical.svg';
import IconVerticalBlue from '../img/logo-vertical-blue.svg';

function Logo(props) {
  let { color = 'blue', variant = 'horizontal', className = '' } = props;
  let Icon = IconHorizontalBlue;
  let imgClassName = [className, 'site-logo'].join(' ');
  
  if (variant === 'vertical' && color === 'white') {
    Icon = IconVertical;
  } else if (variant === 'vertical' && color !== 'white') {
    Icon = IconVerticalBlue;
  } if (variant === 'horizontal' && color === 'white') {
    Icon = IconHorizontal;
  }
  // Default case is horizontal blue

  return (
    <img className={ imgClassName } src={Icon} alt="Site logo" />
  );
}

export default Logo;