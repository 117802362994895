import Nav from 'react-bootstrap/Nav';
import Facebook from '../img/social-facebook.svg';
import Instagram from '../img/social-instagram.svg';
import Twitter from '../img/social-twitter.svg';
import FacebookBlue from '../img/social-facebook-blue.svg';
import InstagramBlue from '../img/social-instagram-blue.svg';
import TwitterBlue from '../img/social-twitter-blue.svg';

function SocialsNav(props) {
  const { color = 'white' } = props;
  const iconFacebook = color === 'blue' ? FacebookBlue : Facebook;
  const iconInstagram = color === 'blue' ? InstagramBlue : Instagram;
  const iconTwitter = color === 'blue' ? TwitterBlue : Twitter;

  return (
    <Nav className="socials-nav">
      <Nav.Item>
        <Nav.Link>
          <img src={iconFacebook} alt="Facebook" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link>
          <img src={iconInstagram} alt="Instagram" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link>
          <img src={iconTwitter} alt="Twitter" />
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default SocialsNav;