import {
  exportDealsCSV,
  industryKeywordsMap,
  locationKeywordsMap,
  moneyStringToNumber,
  setDataEntryIndustriesForSearch,
  setDataEntryLocationsForSearch,
  shortLocationKeywordsMap,
} from '../utils';
import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import DashboardPage from '../containers/DashboardPage';
import DealsTable from '../components/DealsTable';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Pagination from '../components/Pagination';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { authFetch } from '../auth';
import axios from 'axios';
import config from '../config.json';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

function Dashboard() {
  const history = useHistory();
  const viewRowsAmounts = [10, 20, 50];
  const [page, setPage] = useState(1);
  const [viewRows, setViewRows] = useState(viewRowsAmounts[0]);
  let [data, setData] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [creditsAmount, setCreditsAmount] = useState(null);
  const [industryFilter, setIndustryFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minRevenue, setMinRevenue] = useState('');
  const [maxRevenue, setMaxRevenue] = useState('');
  const [minCashFlow, setMinCashFlow] = useState('');
  const [maxCashFlow, setMaxCashFlow] = useState('');
  const [sortConfig, setSortConfig] = useState({});
  const [profileData, setProfileData] = useState(null);
  const [profileDataStatus, setProfileDataStatus] = useState(null);

  const postProcessDealsData = allDeals => {
    // Add industries and locations for search
    allDeals = allDeals.map(deal => {
      setDataEntryIndustriesForSearch(deal);
      setDataEntryLocationsForSearch(deal);
      return deal;
    });
    // Change location field
    allDeals = allDeals.map(deal => {
      let dealLocation = deal['Location'];
      let searchLocations = deal['search_locations'];
      let searchIndustries = deal['search_industries'];

      if (dealLocation && searchLocations && searchLocations.length > 0) {
        deal['Location'] = searchLocations.join(', ');
      }

      if (searchIndustries && searchIndustries.length > 0) {
        deal['Industry'] = searchIndustries.join(', ');
      }

      return deal;
    });

    // parse revenue and cash flow to number
    allDeals = allDeals.map(deal => {
      let revenue = deal['Revenue'];
      let cashFlow = deal['Cash Flow'];

      if (typeof revenue === 'string') {
        let revenueAsNumber = moneyStringToNumber(revenue);

        if (typeof revenueAsNumber === 'number' && !isNaN(revenueAsNumber)) {
          deal['revenue_as_number'] = revenueAsNumber;
          deal['Revenue'] = currencyFormat.format(revenueAsNumber);
        }
        else if (revenueAsNumber === 'money is range value') {
          deal['revenue_as_number'] = null;
          deal['Revenue'] = 'Not Disclosed';
        }
      } else if (typeof revenue === 'number') {
        deal['revenue_as_number'] = revenue;
      }

      if (typeof cashFlow === 'string') {
        let cashFlowAsNumber = moneyStringToNumber(cashFlow);

        if (typeof cashFlowAsNumber === 'number' && !isNaN(cashFlowAsNumber)) {
          deal['cash_flow_as_number'] = cashFlowAsNumber;
          deal['Cash Flow'] = currencyFormat.format(cashFlowAsNumber);
        }
        else if (cashFlowAsNumber === 'money is range value') {
          deal['cash_flow_as_number'] = null;
          deal['Cash Flow'] = 'Not Disclosed';
        }
      } else if (typeof cashFlow === 'number') {
        deal['cash_flow_as_number'] = cashFlow;
      }

      return deal;
    });

    // Remove deals with empty industry
    allDeals = allDeals.filter(deal => {
      return typeof deal['Industry'] === 'string' && deal['Industry'].trim().length > 0;
    });
    // Remove other invalid deals
    // allDeals = allDeals.filter(deal => {
    //   if (deal['revenue_as_number'] === undefined || isNaN(deal['revenue_as_number'])) {
    //     return false;
    //   }

    //   if (deal['search_industries'] === undefined || deal['search_industries'].length === 0) {
    //     return false;
    //   }

    //   if (deal['search_locations'] === undefined || deal['search_locations'].length === 0) {
    //     return false;
    //   }

    //   return true;
    // });

    return allDeals;
  }
  const updateCreditsAmount = () => {
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/auth/credits'
    }).then(response => {
      if (response.data.total_credits !== undefined) {
        setCreditsAmount(response.data.total_credits);
      }
    });
  }
  useEffect(() => {
    // Fetch deals partial data and unlocked deals
    Promise.all([
      authFetch({
        method: 'GET',
        url: config.SERVER_URL + '/api/auth/credits'
      }),
      authFetch({
        method: 'GET',
        url: config.SERVER_URL + '/api/deals'
      }),
      authFetch({
        method: 'GET',
        url: config.SERVER_URL + '/api/unlocked-deals'
      })
    ]).then(([credits, dealsResponse, unlockedDealsResponse]) => {
      const deals = dealsResponse.data;
      const unlockedDeals = unlockedDealsResponse.data;
      const creditsData = credits.data;

      // try {
      //   let test = deals.filter(deal => {
      //     if (deal['Location'] === null || deal['Title'] === null) {
      //       return false;
      //     }
      //     return deal['Location'].includes('Wyoming') && deal['Title'].includes('Motorcycle');
      //   });
      //   console.log(test);
      // } catch (error) {
      //   console.error(error);
      // }

      let creditsSubscriptionStatus = creditsData.subscription ? creditsData.subscription.status : false;
      setSubscriptionStatus(creditsSubscriptionStatus);
      setCreditsAmount(creditsData.total_credits);
      
      unlockedDeals.map(deal => {
        deal.unlocked = true;
        return deal;
      });

      // Get unlocked deals first
      let allDeals = unlockedDeals.slice();
      // Then add locked deals that are missed
      deals.forEach(deal => {
        for (let i = 0; i < unlockedDeals.length; i++) {
          if (deal.id === unlockedDeals[i].id) {
            return;
          }
        }

        allDeals.push(deal);
      });
    
      // Sort deals by id
      allDeals.sort((first, next) => {
        return first.id - next.id;
      });

      console.log('azka', allDeals)
      allDeals = postProcessDealsData(allDeals)
      console.log('azka', allDeals)

      setData(allDeals);
    }).catch(error => {
      console.error(error);

      if (error.response && error.response.data && !error.response.data.email_verified) {
        history.replace('/verify-account');
      }
    });

    // Fetch data
    authFetch({
      url: config.SERVER_URL + '/api/auth/profile'
    }).then(response => {
      setProfileData(response.data);
      setProfileDataStatus(response.status);
    }).catch(error => {
      if (error.response) {
        setProfileDataStatus(error.response.status);
      } else {
        setProfileDataStatus(400);
      }
    });
  }, []);
  const handleSetViewRows = rowsAmount => {
    setViewRows(rowsAmount);
    setPage(1);
  }
  const handleUnlockDeal = dealId => {
    return authFetch({
      method: 'POST',
      url: config.SERVER_URL + '/api/unlock-deals',
      data: {
        deal_id: dealId
      }
    }).then(response => {
      if (response.status === 200) {
        authFetch({
          method: 'GET',
          url: config.SERVER_URL + '/api/deals/' + dealId
        }).then(response => {
          const dealDetails = response.data;
          dealDetails.unlocked = true;
          let newDealsData = data.map(deal => {
            if (deal.id === dealDetails.id) {
              return dealDetails;
            } else {
              return deal;
            }
          });
          
          newDealsData = postProcessDealsData(newDealsData);
          setData(newDealsData);
          updateCreditsAmount();
        })
      }
    }).catch(error => {
      console.error(error);

      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      }
    });
  }
  const handleExportTable = () => {
    let dealsForExport = data.filter(deal => subscriptionStatus === 'active'? selectedDeals.includes(deal.id) : deal.unlocked && selectedDeals.includes(deal.id));
    exportDealsCSV(dealsForExport);
  }
  const handleSearchFunder = () => {
    if(selectedDeals.length === 0 || selectedDeals.length > 1) {
      alert("Please select one deal");
      return;
    }

    alert("In Progress, can you wait few seconds?");

    let dealsForExport = data.filter(deal => selectedDeals.includes(deal.id));
    dealsForExport.forEach(deal => {
      deal.reference_id = "keyword"+deal.id;
      deal.title = deal.Title;
      deal.description = deal.Description;
      deal.country = "US";
      deal.state = deal.Location;
      deal.industry = deal.Industry.split(',')[0];
      deal.revenue = deal.revenue_as_number;
      deal.ebitda = deal.cash_flow_as_number;
      deal.source_name = deal.title;
      deal.img_url = "https://dealzone.us/static/media/logo-vertical-blue.b8158dfe.svg";
      deal.link_url = "https://dealzone.us/dashboard";
      deal.status = "delete";
    });

    console.log("Payload", JSON.stringify(dealsForExport));

    axios.post(
      config.SERVER_URL + '/api/search-funder', dealsForExport
    ).then(function (response) {
      const resp = JSON.parse(response.data);
      if(resp.added>=1 || resp.updated>=1 || resp.deleted>=1)
        alert("Operation Successful. Thank you!");
      console.log(response);
    }).catch(function (error) {
      console.log("error", error)
    });
  }
  const handleSelectDealsChange = deals => {
    setSelectedDeals(deals);
  }

  const handleIndustrySelect = event => {
    setPage(1);
    setSelectedDeals([]);
    // setIndustryFilter(event.target.value);
    setIndustryFilter(event.length>0 && Array.isArray(event) ? event.map(i=>i.label): null);
  }
  const handleLocationSelect = event => {
    setPage(1);
    setSelectedDeals([]);
    // setLocationFilter(event.target.value);
    setLocationFilter(event.length>0 && Array.isArray(event) ? event.map(i=>i.label): null);
  }
  const handleSearchKeywordChange = event => {
    const keyword = event.target.value ? event.target.value : '';
    setSearchKeyword(keyword.toLowerCase());
  }
  const handleMinRevenueChange = event => {
    setMinRevenue(event.target.value);
  }
  const handleMaxRevenueChange = event => {
    setMaxRevenue(event.target.value);
  }
  const handleMinCashFlowChange = event => {
    setMinCashFlow(event.target.value);
  }
  const handleMaxCashFlowChange = event => {
    setMaxCashFlow(event.target.value);
  }

  let tableData = data;
  let dataChunks = [];

  // do filters
  if (tableData) {
    tableData = data.filter(entry => {
      let valid = true;
      if (industryFilter && valid) {
        valid = entry['search_industries'] ? industryFilter.map(keyword=>entry['search_industries'].includes(keyword)).includes(true) : false;
      }

      if (locationFilter && valid) {
        valid = entry['search_locations'] ? locationFilter.map(keyword=>entry['search_locations'].includes(keyword)).includes(true) : false;
      }

      if (minRevenue && valid) {
        valid = entry['revenue_as_number'] ? entry['revenue_as_number'] >= minRevenue : false;
      }

      if (maxRevenue && valid) {
        valid = entry['revenue_as_number'] ? entry['revenue_as_number'] <= maxRevenue : false;
      }

      if (minCashFlow && valid) {
        valid = entry['cash_flow_as_number'] ? entry['cash_flow_as_number'] >= minCashFlow : false;
      }

      if (maxCashFlow && valid) {
        valid = entry['cash_flow_as_number'] ? entry['cash_flow_as_number'] <= maxCashFlow : false;
      }

      if (searchKeyword && valid) {
        let title = entry['Title'];
        let industry = entry['Industry'];

        let validByTitle = typeof title === 'string' && title.length ? title.toLowerCase().includes(searchKeyword) : false;
        let validByIndustry = typeof industry === 'string' && industry.length ? industry.toLowerCase().includes(searchKeyword) : false;
        let result = [validByTitle, validByIndustry].filter(v => v).length > 0;

        valid = result;
      }

      return valid;
    });
  }

  // do sort
  if (tableData && (sortConfig.fieldName && sortConfig.direction)) {
    tableData = tableData.sort((a, b) => {
      const defaultValue = sortConfig.fieldName === 'cash_flow_as_number' ? 0 : '';

      const aValue = a[sortConfig.fieldName] ? a[sortConfig.fieldName] : defaultValue;
      const bValue = b[sortConfig.fieldName] ? b[sortConfig.fieldName] : defaultValue;
      let result = 0;

      if (aValue > bValue) {
        result = 1;
      } else if (aValue < bValue) {
        result = -1;
      }
  
      if (sortConfig.direction === 'descending') {
        result *= -1;
      }

      return result;
    });
  }

  if (tableData) {
    for (let i = 0; i < tableData.length / viewRows; i++) {
      let startIndex = viewRows * i;
      let endIndex = viewRows * (i + 1);
      let chunk = tableData.slice(startIndex, endIndex);
      dataChunks.push(chunk);
    }
  }

  if (dataChunks.length === 0) {
    dataChunks = [[]];
  }

  return (
    <DashboardPage>
      <div className="container-fluid mb-4">
        <h2 className="section-title">Deal Screener</h2>
      </div>
      <div className="container-fluid">
        {
          data ? (
            <>
              <div className="dashboard-actions">
                <div className="dashboard-action dashboard-action-search">
                  <InputGroup>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      onChange={ debounce(handleSearchKeywordChange, 500) }
                    />
                  </InputGroup>
                </div>
                <div className="dashboard-action dashboard-action-sort">
                  <DropdownButton id="dropdown-basic-button" title={ `${viewRows} rows` } variant="outline-primary">
                    { viewRowsAmounts.map(amount => <Dropdown.Item key={ amount } onClick={ () => handleSetViewRows(amount) }>{ `${amount} rows` }</Dropdown.Item>) }
                  </DropdownButton>
                </div>
                <div className="dashboard-action dashboard-action-export">
                  <Button onClick={ handleExportTable } disabled={ selectedDeals.length === 0 }>Export Selected</Button>
                </div>
              </div>
              <div className="search-filters-wrapper">
                <SearchFilters
                 data={ data }
                 subscriptionStatus={ subscriptionStatus }
                 onIndustrySelect={ handleIndustrySelect }
                 onLocationSelect={ handleLocationSelect }
                 onMinRevenueChange={ handleMinRevenueChange }
                 onMaxRevenueChange={ handleMaxRevenueChange }
                 onMinCashFlowChange={ handleMinCashFlowChange }
                 onMaxCashFlowChange={ handleMaxCashFlowChange }
                />
              </div>
              { profileData && profileData.is_staff === true ?
              <Form className="mb-4">
                <Row xs={1} lg={2} xl={4}>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Search Funder (Internal Usage)</Form.Label>
                    <Row>
                      <Col>
                        <img onClick={ handleSearchFunder } style={{cursor: 'pointer'}} src="https://55550cf88fb9105859d2-ecc273435fde99d2e690dfef78341117.ssl.cf5.rackcdn.com/img/trimmedsquirrel.gif?v2" width="100" title="Select deals & Post in Search Funder" />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Form> : null }
              <DealsTable
                data={ dataChunks.length > 1 ? dataChunks[page - 1] : dataChunks[0]}
                onUnlockDeal={ handleUnlockDeal }
                subscriptionStatus={ subscriptionStatus }
                selectedDeals={ selectedDeals }
                onSelectedChange={ handleSelectDealsChange }
                allDeals={ tableData }
                creditsAmount={ creditsAmount }
                sortConfig={ sortConfig }
                onSort={ setSortConfig }
              />
              { dataChunks.length > 1 && <Pagination
                count={ dataChunks.length }
                page={ page }
                onSelect={ page => setPage(page) }
              /> }
            </>
          ) : (
            <Spinner animation="grow" variant="primary" />
          )
        }
      </div>
    </DashboardPage>
  )
}

function SearchFilters({
  subscriptionStatus,
  onIndustrySelect,
  onLocationSelect,
  onMinRevenueChange,
  onMaxRevenueChange,
  onMinCashFlowChange,
  onMaxCashFlowChange,
}) {
  const industries = Object.keys(industryKeywordsMap).sort();
  const locations = Object.keys(locationKeywordsMap).sort();
  const dealTypes = ['Buyout', 'Growth Capital', 'Co-investment', 'Equity Raise', 'Debt Financing'];
  const representatives = ['Business Broker', 'Owner or Company Representat', 'Investment Group', 'PE group', 'Other'];

  return (
    <Form className="mb-4">
      <Row xs={1} lg={2} xl={4}>
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Industry</Form.Label>
          <Select isMulti placeholder="Choose Industry" style={{overflow:"scroll"}} options={industries.map(i=>({"value":industries.indexOf(i)+1, "label": i}))} onChange={onIndustrySelect} />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Location</Form.Label>
          <Select isMulti placeholder="Choose Location" options={locations.map(i=>({"value":locations.indexOf(i)+1, "label": i}))} onChange={onLocationSelect} />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Revenue</Form.Label>
          <Row>
            <Col>
              <Form.Control placeholder="Min" type="number" style={{height:"38px"}} step="1000" min="0" onChange={ onMinRevenueChange } />
            </Col>
            <Col>
              <Form.Control placeholder="Max" type="number" style={{height:"38px"}} step="1000" min="0" onChange={ onMaxRevenueChange } />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>EBITDA/CF/SDE</Form.Label>
          <Row>
            <Col>
              <Form.Control placeholder="Min" type="number" style={{height:"38px"}} step="1000" min="0" onChange={ onMinCashFlowChange } />
            </Col>
            <Col>
              <Form.Control placeholder="Max" type="number" style={{height:"38px"}} step="1000" min="0" onChange={ onMaxCashFlowChange } />
            </Col>
          </Row>
        </Form.Group>
        { subscriptionStatus === 'active' && <>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Deal type</Form.Label>
            <Form.Control as="select" defaultValue="Choose Industry" disabled={ true }>
              <option>Choose Type</option>
              { dealTypes.map( item => <option key={ item }>{ item }</option> ) }
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Representative</Form.Label>
            <Form.Control as="select" defaultValue="Choose Industry" disabled={ true }>
              <option>Choose Representative</option>
              { representatives.map( item => <option key={ item } value={ item }>{ item }</option> ) }
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Date Added</Form.Label>
            <Row>
              <Col>
                <Form.Control placeholder="Min" disabled={ true } />
              </Col>
              <Col>
                <Form.Control placeholder="Max" disabled={ true } />
              </Col>
            </Row>
          </Form.Group>
        </> }
      </Row>
    </Form>
  );
}

export default Dashboard;