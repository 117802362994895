import { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Hero from '../containers/Hero';
import SocialsNav from '../components/SocialsNav';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ValidationFeedback from "../components/ValidationFeedback";
import * as axios from 'axios';
import config from '../config.json';
import Alert from 'react-bootstrap/Alert';

import HeroBackground from '../img/hero-map.png';

function Contacts() {
  const [formAlert, setFormAlert] = useState({message: "", show: false, variant: "success"});

  const schema = yup.object().shape({
    Name: yup.string().required(),
    Message: yup.string().required(),
    Email: yup.string().email().required(),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    const { Name, Email, Message } = data;

    axios.post(config.SERVER_URL + '/api/contact-us', {
      name: Name,
      email: Email,
      message: Message,
    }).then(response => {
      if (response.data.message) {
        const alert_variat = response.status < 400 ? "success" : "danger"
        setFormAlert({"message": response.data.message, "variant": alert_variat, "show": true});
      }
    }).catch(error => {
      if (error.response.data.message) {
        setFormAlert({"message": error.response.data.message, "variant": "danger", "show": true});
      }
      else if (error.response.data.error) {
        setFormAlert({"message": error.response.data.error, "variant": "danger", "show": true});
      }
      else {
        setFormAlert({"message": 'Your message has not been send successfully', "variant": "danger", "show": true});
      }
    });
  }

  const closeFormAlert = () => {
    setFormAlert(prevState => ({
      message: "",
      variant: "success",
      show: false
    }));
  }

  return (
    <>
      <Header />
      <Hero
        backgroundImage={ HeroBackground }
        backgroundDarkness={ 0 }
        className="contacts-page-hero"
      />
      <div className="site-content">
        <section id="info-and-form">
          <div className="container">
            <div className="contact-info-block-wrapper mb-5">
              <div className="contact-info-block px-5 py-4">
                <div className="row">
                  <div className="col-12 col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-between">
                    <h2 className="mb-4">Our Contact Info</h2>
                    <ul className="contact-info-list">
                      <li className="address">9424 Baymeadows road, Ste 250 Jacksonville, FL 32256</li>
                      <li className="phone"></li>
                      <li className="mail">support@dealzone.us</li>
                    </ul>
                    <SocialsNav color="blue" />
                  </div>
                  <div className="col-12 col-lg-6 order-1 order-lg-2 col-reach-us-out">
                    <h2 className="mb-4">Reach us out</h2>
                    <Alert show={formAlert.show} variant={formAlert.variant} style={{whiteSpace: 'pre-line'}} onClose={ closeFormAlert } dismissible>{ formAlert.message }</Alert>
                    <Form onSubmit={ handleSubmit(onSubmit) }>
                      <Form.Group>
                        <Form.Control { ...register('Message') } as="textarea" placeholder="Your message" rows="5" isInvalid={ errors.Message } />
                        <ValidationFeedback type="invalid" validationResult={ errors.Message } />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control { ...register('Name') } type="text" placeholder="Your name" isInvalid={ errors.Name } />
                        <ValidationFeedback type="invalid" validationResult={ errors.Name } />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control { ...register('Email') } type="text" placeholder="Your email" isInvalid={ errors.Email } />
                        <ValidationFeedback type="invalid" validationResult={ errors.Email } />
                      </Form.Group>
                      <Button type="submit" block>Send Your Message</Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="faqs">
          <div className="container faqs-container pb-5">
            <h2 className="text-center text-40-24 mb-4">Overview</h2>
            <div className="text-center">
              <p>
              DealZone allows an M&A professional to spend more time analyzing, closing, and growing businesses and less time sourcing transactions.  DealZone helps stop the fragmentation and wasted time pouring over emails and online listings by aggregating everything in an easy-to-use graphical user interface.  DealZone sources investable opportunities from 3 different resources:
              </p>
            </div>
            <div className="overview-timeline-container mb-5 py-4">
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="px-4">
                      <h3>Using AI</h3>
                      <div>Using AI to scape the web and email listings</div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="px-4">
                      <h3>Post Deals</h3>
                      <div>Allowing intermediaries to post deals</div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="px-4">
                      <h3>Incentivizing members to unlock deals</h3>
                      <div>
                        Incentivizing members to share deals in exchange for credits so they can unlock deals that fit their investment criteria
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
            <h2 className="text-center text-40-24 mb-4">Who's DealZone useful for?</h2>
            <div className="text-center">
              <p>
                DealZone’s target demographic is the M&A industry professional.  Specifically, private equity, search funds, entrepreneurs, family offices, business brokers, investment bankers, business owners, and service providers. DealZone allows you to spend more time analyzing and closing and less time sourcing.
              </p>
            </div>
            <div>
              <h3 className="text-center my-4 text-24-18">How accurate is the data provided by DealZone?</h3>
              <p className="text-center">
                Though short of perfection, DealZone maintains strong quality control system. The founder has a strong background in quantitative finance and spent a lot of his past wrangling and cleaning data. Data accuracy is maintained by:
              </p>
              <ul>
                <li>
                  Simple cleaning algorithms used to make sure revenues, EBITDA, CF, and other metrics are “logical.”
                </li>
                <li>
                  Analysts reviewing and verifying number for accuracy if the numbers are outside of normal parameters.
                </li>
                <li>
                  By obtaining the data directly from the source in the case of intermediaries and members posting their own deals.
                </li>
              </ul>
            </div>
          </div>
          <section id="questions">
            <div className="container faqs-container py-5 mb-5 mt-5">
            <h2 className="my-5 section-header text-center text-40-24">Searching and viewing deals</h2>
            <Accordion className="faqs-accordion mb-5 pb-5" onSelect={ event => console.log(event) }>
              <Card>
                <FaqsCardHeader eventKey="0">
                How do you screen for Deals?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      From the ‘Deal Screener’ dropdown menu, select your investment criteria, including:
                    </p>
                    <ol>
                      <li>
                        Industry Classification<br />
                        - Select from available NAICS Codes.  Further drill down by subsectors
                      </li>
                      <li>
                        Location<br />
                        - Filter by US region, then by state. 
                      </li>
                      <li>
                        Revenue
                      </li>
                      <li>
                        EBITDA/Cash flow
                      </li>
                      <li>
                        Deal type (Buyout, Growth capital, Debt Financing, Club deal, etc.)
                      </li>
                      <li>
                        Source of intermediary (Broker, Investment Banker, Private Equity, DealZone Member) 
                      </li>
                      <li>
                        Date uploaded 
                      </li>
                    </ol>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="1">
                  How do I apply a credit and unlock access to a deal?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      Simply select the checkbox to the left of each investment opportunity.  Then click the open icon.  You 
                      can also select multiple opportunities at once and then click ‘OPEN’.  After the opportunity is opened, 
                      you can will see additional deal stats, as well as the representative or intermediary’s contact information 
                      for executing an NDA and receiving the CIM.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="2">
                  How do I share deals?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Go under the ‘Deal Share’ button and upload a .pdf of your investment opportunity that you would like 
                    to share with the community.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                 <FaqsCardHeader eventKey="3">
                  How do I view the deals I have shared or unlocked? 
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      Go to ‘My Deal Opportunity Portfolio’ and view the opportunities you have already opened or shared.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                 <FaqsCardHeader eventKey="4">
                  How do I obtain credits to open deals?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <p>
                      You can open deal listings by adding credits to your account.  Each credit costs $20,
                      and it costs $20 to open 1 opportunity.  You can pay by credit card by going under 
                      “Your Profile,” then “Current Balance,” and then by adding 1, 5, or 10 credits from 
                      the dropdown menu.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="5">
                What if I open a deal and it is no longer on the market, or the data is inaccurate?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <p>
                      Please click the “Report Deal” icon and send a screenshot to <a href="mailto:support@dealzone.us" target="_blank">support@dealzone.us</a>. You will then be either given credit or a refund.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="6">
                  How do I export deals?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <p>
                      Simply select all the deals you wish to export and click “EXPORT 
                      TABLE.”  NOTE: If you are on a monthly or annual subscription, you 
                      may want to download all the deals.  Simply click the icon “SELECT 
                      ALL” and then “EXPORT TABLE.”
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                 <FaqsCardHeader eventKey="7">
                 How to set up your account to receive deal alert notifications to your email?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div>
                      <p>
                        To have email notifications of new deals that are pre-defined by your investment 
                        criteria, do the following:
                      </p>
                      <ol>
                        <li>Go to “DEAL ALERTS”:</li>
                        <li>Select your investment criteria.</li>
                        <li>Name your alert something easy like “Technology 10M+ Revenues”.</li>
                        <li>Select “NOTIFY BY EMAIL.”</li>
                        <li>Click “SAVE.”</li>
                      </ol>
                      <p>
                        You must also select your deal notification frequency:
                      </p>
                      <ol>
                        <li>Daily</li>
                        <li>Weekly</li>
                        <li>Monthly</li>
                      </ol>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <h2 className="text-center my-5 text-40-24">Security Settings</h2>
            <Accordion className="faqs-accordion mb-5 pb-5" onSelect={ event => console.log(event) }>
              <Card>
                <FaqsCardHeader eventKey="0">
                  How do I change my password?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ol>
                      <li>
                        Go to the profile icon in the upper right.
                      </li>
                      <li>
                        Select “SETTINGS” from the dropdown menu.
                      </li>
                      <li>
                        Click on “CHANGE PASSWORD
                      </li>
                      <li>
                        Enter your current password.
                      </li>
                      <li>
                        Enter your new password twice.
                      </li>
                      <li>
                        Click “SAVE”
                      </li>
                    </ol>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="1">
                  How do I change email notification preferences?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <ol>
                      <li>
                        Go to Profile
                      </li>
                      <li>
                        Go to “NOTIFICATION SETTINGS”
                      </li>
                      <li>
                        Select the Frequency you desire to send notification alerts based on your 
                        pre-determined investment criteria:
                        <ul>
                          <li>Hourly</li>
                          <li>Daily</li>
                          <li>Weekly</li>
                        </ul>
                      </li>
                    </ol>
                    <p>To alter your investment criteria, go to the “DEAL ALERTS” on the Dashboard on the DealZone home page.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <h2 className="text-center text-40-24 my-5">Industry and Geography Coverage</h2>
            <Accordion className="faqs-accordion mb-5" onSelect={ event => console.log(event) }>
              <Card>
                <FaqsCardHeader eventKey="0">
                  Which locations do you cover?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                    For now, DealZone is focused on the US.  However, this will likely change in the future.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <FaqsCardHeader eventKey="1">
                  Which industries does Interexo have?
                </FaqsCardHeader>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>DealZone covers all industries in the following list:</p>
                    <ol>
                      <li>Wholesale Trade/Distribution</li>
                      <li>Financial Services/Insurance</li>
                      <li>Business Services</li>
                      <li>Healthcare</li>
                      <li>Consumer Goods</li>
                      <li>Utilities & Energy</li>
                      <li>Transportation & Logistics</li>
                      <li>Manufacturing</li>
                      <li>Technology, Saas & eCommerce</li>
                      <li>Food & Beverage</li>
                      <li>Education</li>
                      <li>Health & Fitness</li>
                    </ol>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
          </section>
          <section id="sharing-deals-steps">
            <div className="container faqs-container">
            <h2 className="text-center mb-5 text-40-24">
              Steps to Sharing Deals as an Authorized Representative<br/>
              <span className="text-30-24">(Business Broker, Owner, or Investment Banker)</span>
            </h2>
            <div className="container mb-5">
                <div className="row mb-4">
                  <div className="col-12 col-lg-2 text-center mb-3 mb-lg-0">
                    <span className="step-number">1</span>
                  </div>
                  <div className="col">
                    Submit the opportunity for analysis and verification by DealZone’s analysts.
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-lg-2 text-center mb-3 mb-lg-0">
                    <span className="step-number">2</span>
                  </div>
                  <div className="col">
                    <p>The following information must be specified:</p>
                    <p>
                      a.	Title<br />
                      b.	Industry<br />
                      c.	Location<br />
                      d.	Revenue<br />
                      e.	EBITDA or Cash Flow<br />
                      f.	Deal Type<br />
                      g.	A brief description<br />
                      h.	Your Profile info:<br />
                      <ol type='i'>
                        <li> Name</li>
                        <li> Company</li>
                        <li> Email</li>
                        <li> Phone</li>
                      </ol>
                    </p>
                    <p>While a CIM is optional, it is preferred.  20 credits will be approved upon verification.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-2 text-center mb-3 mb-lg-0">
                    <span className="step-number">3</span>
                  </div>
                  <div className="col">
                    <p>
                      As a member, you can also share deals.  However, be sure that you only share teaser level (meaning general)
                      information and provide an NDA and the contact information of the intermediary.
                      You will also need to submit the same information as an intermediary:
                    </p>
                    <p>
                      a.	Title<br />
                      b.	Industry<br />
                      c.	Location<br />
                      d.	Revenue<br />
                      e.	EBITDA or Cash Flow<br />
                      f.	Deal Type<br />
                      g.	A brief description<br />
                      h.	Your Profile info:<br />
                      <ol type='i'>
                        <li> Name</li>
                        <li> Company</li>
                        <li> Email</li>
                        <li> Phone</li>
                      </ol>
                    </p>
                    <p>
                      While a CIM is optional, it is preferred.  20 credits will be approved upon verification.
                    </p>
                  </div>
                </div>
            </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

function FaqsCardHeader(props) {
  const { children, eventKey, callback } = props;
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  const className = isCurrentEventKey ? 'active' : null;

  return (
    <Card.Header onClick={ decoratedOnClick } className={ className }>
      { children }
    </Card.Header>
  );
}

export default Contacts;