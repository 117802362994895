import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import DashboardPage from '../containers/DashboardPage';
import DealsIcon from '../img/deals.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NoItemsCard from '../containers/DashboardNoItemsCard';
import Pagination from '../components/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import UsersTable from '../components/UsersTable';
import { authFetch } from '../auth';
import config from '../config.json';
import { debounce } from 'lodash';
import { exportUsersCSV } from '../utils';
import { useHistory } from 'react-router-dom';

function Users() {
    const history = useHistory();
    const [data, setData] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const viewRowsAmounts = [5, 10, 20, 50];
    const [page, setPage] = useState(1);
    const [viewRows, setViewRows] = useState(viewRowsAmounts[0]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [sortConfig, setSortConfig] = useState({});

    useEffect(() => {
        // Fetch signed up users with active subscription status
        authFetch({
            method: 'GET',
            url: config.SERVER_URL + '/api/users'
        }).then(response => {
            let users = response.data;

            if (Array.isArray(users)) {
                users = users.map(user => {
                    user['Subscription Status'] = user['Active Subscription'] ? 'A subscriber' : 'Not a subscriber'
                    return user;
                });
            }

            // Sort users by id
            users.sort((first, next) => {
                return first.id - next.id;
            });

            setData(users);
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.error) {
                alert(error.response.data.error);
            }
        });
    }, []);

    const handleSearchKeywordChange = event => {
        const keyword = event.target.value ? event.target.value : '';
        setSearchKeyword(keyword.toLowerCase());
    }

    const handleSetViewRows = rowsAmount => {
        setViewRows(rowsAmount);
        setPage(1);
    }
    
    const handleSelectedUsersChange = users => {
        setSelectedUsers(users);
    }
    
    const handleExportUsers = () => {
        let usersForExport = data.filter(user => selectedUsers.includes(user.id));
        exportUsersCSV(usersForExport);
    }

    let tableData = data;
    
    // do filters
    if (tableData) {
        tableData = data.filter(entry => {
            let valid = true;

            if (searchKeyword && valid) {
                let email = entry['Email'];
                valid = typeof email === 'string' && email.length ? email.toLowerCase().includes(searchKeyword) : false;;
            }

            return valid;
        });
    }

    // do sort
    if (tableData && (sortConfig.fieldName && sortConfig.direction)) {
        tableData = tableData.sort((a, b) => {
            const defaultValue = sortConfig.fieldName === 'Email' ? 0 : '';

            const aValue = a[sortConfig.fieldName] ? a[sortConfig.fieldName] : defaultValue;
            const bValue = b[sortConfig.fieldName] ? b[sortConfig.fieldName] : defaultValue;
            let result = 0;

            if (aValue > bValue) {
                result = 1;
            } else if (aValue < bValue) {
                result = -1;
            }
        
            if (sortConfig.direction === 'descending') {
                result *= -1;
            }

            return result;
        });
    }

    //do pagination
    let dataChunks = [];
    if (tableData) {
        for (let i = 0; i < tableData.length / viewRows; i++) {
            let startIndex = viewRows * i;
            let endIndex = viewRows * (i + 1);
            let chunk = tableData.slice(startIndex, endIndex);
            dataChunks.push(chunk);
        }
    }
    if (dataChunks.length === 0) {
        dataChunks = [[]];
    }

    return (
        <DashboardPage>
            <div className="container-fluid mb-4">
                <h2 className="section-title mb-0">Users</h2>
            </div>
            <div className="container-fluid"> 
                <div className="dashboard-actions">
                    <div className="dashboard-action dashboard-action-search">
                        <InputGroup>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                onChange={ debounce(handleSearchKeywordChange, 500) }
                            />
                        </InputGroup>
                    </div>
                    <div className="dashboard-action dashboard-action-sort">
                        <DropdownButton id="dropdown-basic-button" title={ `${viewRows} rows` } variant="outline-primary">
                        { viewRowsAmounts.map(amount => <Dropdown.Item key={ amount } onClick={ () => handleSetViewRows(amount) }>{ `${amount} rows` }</Dropdown.Item>) }
                        </DropdownButton>
                    </div>
                    <div className="dashboard-action dashboard-action-export">
                        <Button onClick={ handleExportUsers } disabled={ selectedUsers.length === 0 }>Export Selected</Button>
                    </div>
                </div>
                {
                    data === null ? (
                        <Spinner animation="grow" variant="primary" />
                    ) : (
                        <>
                            {
                                typeof data === 'object' && data.length ? <> 
                                    <UsersTable
                                        data={ dataChunks.length > 1 ? dataChunks[page - 1] : dataChunks[0]}
                                        allUsers={ tableData }
                                        onSelectedChange={ handleSelectedUsersChange }
                                        sortConfig={ sortConfig }
                                        onSort={ setSortConfig }
                                    />
                                    { dataChunks.length > 1 && <Pagination
                                        count={ dataChunks.length }
                                        page={ page }
                                        onSelect={ page => setPage(page) }
                                    /> }
                                </> : <>
                                    <NoItemsCard>
                                        <div className="d-flex flex-column align-items-center">
                                            <img src={ DealsIcon } alt="Users" className="mb-3" />
                                            <h3 className="text-center">There are no active users.</h3>
                                        </div>
                                    </NoItemsCard>
                                </>
                            }   
                        </>
                    ) 
                }   
            </div>
        </DashboardPage>
    )
}

export default Users;