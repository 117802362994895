import UserIcon from '../img/user.svg';

function UserAvatar({ avatar = UserIcon, size = 32, onEdit, className = '' }) {
  const style = {
    width: size + 'px',
    height: size + 'px',
    objectFit: 'cover',
    objectPosition: 'center',
  }

  avatar = !!avatar ? avatar : UserIcon;

  return (
    <div className={ `user-avatar ${className}` }>
      <div className="user-avatar-wrapper">
        <img src={ avatar } alt="Avatar" style={ style } />
        { (typeof onEdit === 'function') && <button className="user-avatar-edit-btn" onClick={ onEdit }>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.01992 7.81153C2.04336 7.81153 2.0668 7.80918 2.09023 7.80567L4.06133 7.45996C4.08477 7.45528 4.10703 7.44473 4.12344 7.42715L9.09101 2.45957C9.10188 2.44873 9.1105 2.43585 9.11638 2.42168C9.12226 2.4075 9.12529 2.3923 9.12529 2.37695C9.12529 2.36161 9.12226 2.34641 9.11638 2.33223C9.1105 2.31806 9.10188 2.30518 9.09101 2.29434L7.14336 0.345508C7.12109 0.323242 7.0918 0.311523 7.06016 0.311523C7.02851 0.311523 6.99922 0.323242 6.97695 0.345508L2.00937 5.31309C1.9918 5.33067 1.98125 5.35176 1.97656 5.3752L1.63086 7.34629C1.61946 7.40907 1.62353 7.47368 1.64273 7.53453C1.66192 7.59538 1.69566 7.65063 1.74102 7.69551C1.81836 7.77051 1.91562 7.81153 2.01992 7.81153ZM2.80977 5.76778L7.06016 1.51856L7.91914 2.37754L3.66875 6.62676L2.62695 6.81074L2.80977 5.76778ZM9.3125 8.7959H0.6875C0.480078 8.7959 0.3125 8.96348 0.3125 9.1709V9.59278C0.3125 9.64434 0.354687 9.68653 0.40625 9.68653H9.59375C9.64531 9.68653 9.6875 9.64434 9.6875 9.59278V9.1709C9.6875 8.96348 9.51992 8.7959 9.3125 8.7959Z" fill="white"/>
          </svg>
        </button> }
      </div>
    </div>
  );
}

export default UserAvatar;