import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Logo from './Logo';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import UserAvatar from './UserAvatar';
import Dropdown from 'react-bootstrap/Dropdown';
import React from 'react';
import { logOut } from '../auth';

import BellIcon from '../img/bell.svg';
import MenuIcon from '../img/menu.svg';

function DashboardHeader({ onMenuButtonClick }) {
  const history = useHistory();
  const ProfileDropdown = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="link" onClick={ onClick } style={{ padding: 0, marginLeft: '15px' }}>
      <UserAvatar />
    </Button>
  ));
  const handleLogOut = async () => {
    try {
      await logOut();
    } catch(error) {
      console.error(error);
    }
    history.push('/');
  }

  return (
    <header className="dashboard-header">
      <Navbar className="dashboard-header-navbar">
        <Button className="toggle-sidebar-button" onClick={ onMenuButtonClick }>
          <img src={ MenuIcon } alt="Menu" />
        </Button>
        <Navbar.Brand
          href="/"
          onClick={event => {
            event.preventDefault();
            history.push("/");
          }}
        >
          <Logo color="blue" />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Nav.Item>
            <Link to="/products" className="nav-link">Products</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/contact" className="nav-link">Contact</Link>
          </Nav.Item>
        </Nav>
        <Dropdown>
          <Dropdown.Toggle as={ ProfileDropdown } />
          <Dropdown.Menu style={{ left: 'unset', right: 0, padding: 0 }}>
            <Dropdown.Item>
              <Button variant="link" size="sm" onClick={ () => history.push('/settings') }>Settings</Button>
            </Dropdown.Item>
            <Dropdown.Item>
              <Button variant="link" size="sm" onClick={ handleLogOut }>Log Out</Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="dashboard-header-split"></div>
      </Navbar>
    </header>
  );
}

function NotificationsButton({ notificationsCount }) {
  return (
    <Button className="notifications-button">
      <img className="bell-icon" src={ BellIcon } alt="Notifications" />
      <span className="notifications-count">{ notificationsCount }</span>
    </Button>
  );
}

export default DashboardHeader;