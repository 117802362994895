import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Logo from '../components/Logo';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LoginModal from '../components/LoginModal';
import { useAuth } from '../auth';
import { Switch, Route } from 'react-router-dom';

function Header(props) {
  let { variant = 'blue' } = props;
  let className = `site-header site-header-variant-${variant}`;
  const history = useHistory();
  const handleNavSelect = eventKey => {
    switch (eventKey) {
      case 'log-in':
        history.push('/login');
        break;
      case 'sign-up':
        history.push('/signup');
        break;
      case '/products':
        history.push(eventKey);
        break;
      case '/contact':
        history.push(eventKey);
        break;
    }
  }
  const location = useLocation();
  const pathName = location.pathname;
  const { loggedIn } = useAuth();

  return (
    <header className={className}>
      <div className="container">
        <Navbar expand="lg" variant={ variant === 'blue' ? 'light' : 'dark' }>
          <Navbar.Brand onClick={ () => history.push("/") } style={{ cursor: 'pointer' }} >
            <Logo color={variant} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto" onSelect={ handleNavSelect } activeKey={ pathName }>
              <Nav.Item>
                <Nav.Link eventKey="/products"><span>Products</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="/contact"><span>Contact</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://blog.dealzone.us" ><span>Blog</span></Nav.Link>
              </Nav.Item>
              {
                loggedIn
                ? <>
                  <Nav.Item>
                    <Link to="/profile" className="nav-link"><span>Profile</span></Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to="/dashboard" className="nav-link"><span>Deal Screener</span></Link>
                  </Nav.Item>
                </>
                : <>
                  <Nav.Item>
                    <Nav.Link eventKey='log-in'>Log In</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={ Button }
                      style={{ color: '#fff' }}
                      eventKey='sign-up'
                    >
                      Sign Up
                    </Nav.Link>
                  </Nav.Item>
                </>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/login">
            <LoginModal action="login" />
          </Route>
          <Route path="/signup">
            <LoginModal action="signup" />
          </Route>
          <Route path="/reset-password">
            <LoginModal action="reset-password" />
          </Route>
          <Route path="/verify-account">
            <LoginModal action="verify-account" />
          </Route>
        </Switch>
      </div>
    </header>
  );
}

export default Header;