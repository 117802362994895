import DashboardPage from '../containers/DashboardPage';

function Success() {
  return (
    <DashboardPage>
      <p>Thanks for your order!</p>
    </DashboardPage>
  );
}

export default Success;