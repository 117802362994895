import Card from 'react-bootstrap/Card';

function InvestReasonCard(props) {
  const { title, text, icon } = props;

  return (
    <Card className="invest-reason-card h-100">
      <Card.Body>
        <img className="invest-reason-card-icon mr-4" src={icon} alt={title} />
        <div className="invest-reason-card-content">
          <h3>{ title }</h3>
          <p className="mb-0">{ text }</p>
        </div>
      </Card.Body>
    </Card>
  );
}

export default InvestReasonCard;