import { useEffect, useState } from 'react';
import axios from 'axios';
import config from './config.json';

const storageKey = 'DEALZONE_AUTH';

function logIn(token) {
  if (typeof token === 'string') {
    localStorage.setItem(storageKey, token);
  }
}

function logOut() {
  const token = localStorage.getItem(storageKey);

  return axios({
    method: 'POST',
    url: config.SERVER_URL + '/api/auth/logout',
    headers: {
      'Authorization': `Token ${token}`
    }
  }).then(response => {
    localStorage.removeItem(storageKey);
    return response;
  }).catch(error => {
    localStorage.removeItem(storageKey);
    return error;
  });
}

function useAuth() {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const checkIsLoggedIn = () => {
    setLoggedIn(isLoggedIn());
  }

  useEffect(() => {
    checkIsLoggedIn();
  });

  return { loggedIn, checkIsLoggedIn };
}

function isLoggedIn() {
  const token = localStorage.getItem(storageKey);
  return token !== null && typeof token === 'string';
}

function useLogInWithGoogle() {
  console.log('Use login calleddd');
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const googleLogIn = window.location.hash.length && window.location.hash.includes('#access_token');

    if (googleLogIn) {
      setStatus(1);

      const hash = window.location.hash.split('&')[0].replace('#access_token=', '');
      // to get rid of multiple api calls by same address bar (#access_token=)
      window.history.pushState('', '', '/login?redirecting');
      axios.post(
        config.SERVER_URL + '/api/social-auth/login/google',
        { 'access_token': hash }
      ).then(response => {
        logIn(response.data.token);
        setStatus(response.status);
      }).catch(error => {
        if (error.response) {
          setStatus(error.response.status);
        } else {
          setStatus(0);
        }
      });
    }
  });

  return status;
}

function useLogInWithLinkedin() {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const linkedinLogIn = window.location.search.length && window.location.search.includes('code');
    if (linkedinLogIn) {
      setStatus(1);
      const hash = window.location.search.split('&')[0].replace('?code=', '');
      // to get rid of multiple api calls by same address bar (?code=)
      window.history.pushState('', '', '/login?redirecting');
      axios.post(
        config.SERVER_URL + '/api/social-auth/login/linkedin',
        { 'access_token': hash }
      ).then(response => {
        logIn(response.data.token);
        setStatus(response.status);
      }).catch(error => {
        if (error.response) {
          setStatus(error.response.status);
        } else {
          setStatus(0);
        }
      });
    }
  });

  return status;
}

function authFetch(requestConfig) {
  let token = localStorage.getItem(storageKey);

  if (token === null) {
    return Promise.reject({ response: { status: 400 } });
  }

  let headers = requestConfig.headers || {};
  let withAuthHeader = Object.assign(headers, { 'Authorization': `Token ${token}` });
  let requestConfigWithAuth = Object.assign(requestConfig, { headers: withAuthHeader });

  return axios(requestConfigWithAuth).catch(error => {
    if (error.response && error.response.status === 401) {
      throw logOut().finally(() => {
        window.location.replace('/login');
        return error;
      });
    } else {
      throw error;
    }
  });
}

export { logIn, logOut, useAuth, useLogInWithGoogle, useLogInWithLinkedin, authFetch };