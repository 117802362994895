import { Redirect, Route } from 'react-router-dom';
import { authFetch, useAuth } from '../auth';
import { useEffect, useState } from 'react';

import config from '../config.json';

function ProtectedRoute({ children, path, checkPermission = false, ...rest }) {
  const [profileData, setProfileData] = useState(null)
  const { loggedIn } = useAuth();

  useEffect(() => {
    //For staff
    if (loggedIn && checkPermission){
      // Fetch profile data
      authFetch({
        url: config.SERVER_URL + '/api/auth/profile'
      }).then(response => {
        setProfileData(response.data);
      });
    }
  }, []);
  
  console.log('Loggied in ', loggedIn);
  return (
    <Route path={ path } { ...rest }>
      { 
        checkPermission 
          ? 
            (
            /* For staff */
              loggedIn
                ? profileData && (
                  profileData.is_staff === true
                    ? children
                    : <Redirect to={{
                        pathname: '/'
                      }} />
                )
                : (
                    <Redirect to={{
                      pathname: '/'
                    }} />
                )
            )
          
          : (
            /* Not a staff member */
              loggedIn
                ? children
                : <Redirect to={{
                  pathname: '/login',
                  state: { redirectAfterLoginTo: (path)}
                }} />
            )
      }
    </Route>
  );
}

export default ProtectedRoute;