import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Hero from '../containers/Hero';
import ProductCard from '../components/ProductCard';
import Spinner from 'react-bootstrap/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import EmailBlock from '../components/EmailBlock';
import config from '../config.json';

// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

import HeroBackground from '../img/hero-bg-2.png';
import { useHistory } from 'react-router-dom';
import { SUBSCRIPTION_TYPE_1, SUBSCRIPTION_TYPE_2, SUBSCRIPTION_TYPE_3 } from '../utils/constants';
import { doSubscription } from '../utils/afterLoginAction';
import { authFetch, useAuth } from '../auth';
import { useState, useEffect } from 'react';

// install Swiper modules
SwiperCore.use([Pagination]);

function Products() {
  const history = useHistory();
  const { loggedIn } = useAuth();
  const [buyPlan, setBuyPlan] = useState(true);
  let products;


  useEffect(() => {
    if (loggedIn) {
      authFetch({
          url: config.SERVER_URL + '/api/products'
      }).then(response => {
        products = response.data;
      })
    }
  }, [])

  const handleBtnClick = (subscriptionType) => (event) => {
    setBuyPlan(false)
    event.preventDefault();
    if (loggedIn) {
      doSubscription(subscriptionType, products)
    } else {
      localStorage.setItem('dZ-afterLoginAction', `subscription::${subscriptionType}`);
      history.replace('/signup');
    }
  }


  return (
    <>
      <Header />
      <Hero
        title="Products"
        backgroundImage={ HeroBackground }
      />
      <div className="site-content">
        <section id="choose-plan">
          <div className="container">
            <h2 className="text-center section-header my-5">Choose your suitable plan</h2>
            <div className="text-center section-header my-5">
              { buyPlan ? '' : <Spinner animation="grow" variant="primary" /> }
            </div>
            <Swiper
              className="products-slider"
              slidesPerView={1}
              pagination={true}
              breakpoints={{
                "640": {
                  "slidesPerView": 1,
                  "spaceBetween": 20
                },
                "768": {
                  "slidesPerView": 2,
                  "spaceBetween": 30
                },
                "1024": {
                  "slidesPerView": 3,
                  "spaceBetween": 30
                }
              }}
            >
              <SwiperSlide>
                <ProductCard
                  buttonText="Just a Fling"
                  title="A la Carte"
                  features={[ 'Pay for credits which unlock deals', '1 credit per deal (not based on deal size)', 'Pay as you go' ]}
                  price={ <><strong>$10</strong> <span>/ credit</span></> }
                  onBtnClick= { handleBtnClick(SUBSCRIPTION_TYPE_1) }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ProductCard
                  buttonText="Going Steady"
                  title="Monthly Subscription"
                  features={[ 'All access to our dynamic deal database', '7,000 - 8,000 opportunities', 'Updated weekly with new opportunities', 'Select deal which fit your investment criteria', 'Weekly email updates catered to your criteria' ]}
                  price={ <><strong>$145</strong> <span>/ month</span></> }
                  onBtnClick= { handleBtnClick(SUBSCRIPTION_TYPE_2) }
                />
              </SwiperSlide>
              <SwiperSlide>
                <ProductCard
                  name="Marketing"
                  title="Annual Subscription"
                  buttonText="Marital Bliss"
                  features={[ 'All access to our dynamic database at a reduced rate', '7,000 - 8,000 opportunities', 'Updated weekly with new opportunities', 'Select deal which fit your investment criteria', 'Weekly email updates catered to your criteria', 'Statistics on industries attracting capital' ]}
                  price={ <><strong>$1400</strong> <span>/ year</span></> }
                  onBtnClick= { handleBtnClick(SUBSCRIPTION_TYPE_3) }
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        <section id="email">
          <EmailBlock />
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Products;