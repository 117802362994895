import DashboardPage from '../containers/DashboardPage';
import NoItemsCard from '../containers/DashboardNoItemsCard';
import Button from 'react-bootstrap/Button';

import ChatIcon from '../img/chat.svg';

function Chat() {
  return (
    <DashboardPage>
      <div className="container-fluid">
        <h2 className="section-title">Chat</h2>
      </div>
      <section id="chat">
        <div className="container-fluid my-4">
          <NoItemsCard>
            <div className="d-flex flex-column align-items-center">
              <img src={ ChatIcon } alt="Chat" className="mb-3" />
              <h3>This is your chat screen</h3>
              <div>You haven’t started conversiation yet</div>
              <Button variant="outline-primary" className="mt-5">
                Start Conversation
              </Button>
            </div>
          </NoItemsCard>
        </div>
      </section>
    </DashboardPage>
  )
}

export default Chat;