import ReactPaginate from 'react-paginate';

function Pagination({ count, page: currentPage = 1, onSelect, className = '' }) {
  let classNames = ['pagination', className].join(' ');
  const prevIcon = (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
    </svg>
  );
  const nextIcon = (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );

  return (
    <>
    <div className={ classNames }>
      <ReactPaginate
        pageCount={count}
        containerClassName="pagination-items"
        pageClassName="pagination-item"
        activeLinkClassName="current"
        initialPage={ currentPage - 1 }
        forcePage={ currentPage - 1 }
        onPageChange={ pageObj => onSelect(pageObj.selected + 1) }
        previousLabel={ prevIcon }
        nextLabel={ nextIcon }
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
      />
    </div>
    </>
  );
}

export default Pagination;