import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { TableSortingButton } from '../utils';

function DealsTable({
  data = [], onUnlockDeal, subscriptionStatus, onSelectedChange = () => {}, allDeals, creditsAmount, sortConfig, onSort
}) {
  const [dealDetailsToShow, setDealDetailsToShow] = useState(null);
  const [confirmUnlockDealId, setConfirmUnlockDealId] = useState(null);
  const [unlockDealAlerts, setUnlockDealAlerts] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);

  const selectAllChecked = allDeals.length > 0 && selectedDeals.length === allDeals.length;
  const handleConfirmUnlockModalClose = () => {
    setConfirmUnlockDealId(null);
  }
  const handleUnlockDeal = async dealId => {
    onUnlockDeal(confirmUnlockDealId).then(() => {
      handleConfirmUnlockModalClose();
    });
  }
  const handleViewDetails = async deal => {
    setDealDetailsToShow(deal);
  }
  const handleDealDetailsClose = () => {
    setDealDetailsToShow(null);
  }
  const updateSelectedDeals = deals => {
    let newDeals = selectedDeals.slice();

    deals.forEach(deal => {
      if (deal.selected && !selectedDeals.includes(deal.id)) {
        newDeals.push(deal.id);
      } else if (!deal.selected && selectedDeals.includes(deal.id)) {
        newDeals = newDeals.filter(d => d !== deal.id);
      }
    });

    setSelectedDeals(newDeals);
    onSelectedChange(newDeals);
  }
  const handleCheckAll = event => {
    let checked = event.target.checked;
    let deals = allDeals.map(deal => ({ id: deal.id, selected: checked }));
    updateSelectedDeals(deals);
  }
  const handleEventCheck = (event, dealId) => {
    let selected = !selectedDeals.includes(dealId);
    let dealToSelect = {
      id: dealId,
      selected
    };

    if (selectAllChecked) {
      let unchacked = allDeals.map(deal => ({ id: deal.id, selected: false }));
      updateSelectedDeals([...unchacked, dealToSelect]);
    } else {
      updateSelectedDeals([dealToSelect]);
    }
  }

  const rows = data.map((deal, idx) => {
    let {
      Title,
      Industry,
      Location,
      "Cash Flow":CashFlow,
    } = deal;
    
    return (
      <tr
        key={deal.id}
        data-deal-json={ JSON.stringify({ CashFlow: deal['Cash Flow'], CashFlowAsNumber: deal['cash_flow_as_number']}) }
      >
        <td><Form.Check type="checkbox" checked={ selectedDeals.includes(deal.id) } onChange={ (event) => handleEventCheck(event, deal.id) } /></td>
        <td>{ Industry }</td>
        <td className="td-location">{ Title }</td>
        <td className="td-location">{ Location }</td>
        <td className="td-cash-flow td-nowrap">{ CashFlow }</td>
        <td className="text-center">
          {
            deal.unlocked || subscriptionStatus === 'active'
              ? <Button style={{ whiteSpace: 'nowrap' }} variant="outline-primary" onClick={ () => handleViewDetails(deal) }>View details</Button>
              : <Button style={{ whiteSpace: 'nowrap' }} onClick={ () => setConfirmUnlockDealId(deal.id) }>Unlock deal</Button>
          }
        </td>
      </tr>
    );
  });

  return (
    <div className="table-responsive table-scroll-wrapper">
      <Table className="dashboard-table">
        <thead>
          <tr>
            <td>
              <Form.Check
                type="checkbox"
                onChange={ handleCheckAll }
                checked={ selectAllChecked }
              />
            </td>
            <td>Industry <TableSortingButton fieldName="Industry" onSort={ onSort } sortConfig={ sortConfig } /></td>
            <td>Title <TableSortingButton fieldName="Title" onSort={ onSort } sortConfig={ sortConfig } /></td>
            <td>Location <TableSortingButton fieldName="Location" onSort={ onSort } sortConfig={ sortConfig } /></td>
            <td>EBITDA <TableSortingButton fieldName="cash_flow_as_number" onSort={ onSort } sortConfig={ sortConfig } /></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          { rows }
        </tbody>
      </Table>
      <Modal show={dealDetailsToShow !== null} onHide={handleDealDetailsClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deal details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { dealDetailsToShow !== null && <Table style={{ boxShadow: 'none' }}>
            <tbody>
              <tr>
                <td style={{ borderTop: 'none' }}>Title</td>
                <td style={{ borderTop: 'none' }}>{ dealDetailsToShow.Title }</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{ dealDetailsToShow.Location }</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>{ dealDetailsToShow.Price }</td>
              </tr>
              <tr>
                <td>Revenue</td>
                <td>{ dealDetailsToShow.Revenue }</td>
              </tr>
              <tr>
                <td>Cash Flow</td>
                <td>{ dealDetailsToShow['Cash Flow'] }</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{ dealDetailsToShow.Description }</td>
              </tr>
              <tr>
                <td>Link</td>
                <td><a href={ dealDetailsToShow.URL } target="_blank">Deal Link</a></td>
              </tr>
              <tr>
                <td>Source</td>
                <td>
                  <a
                    href={ dealDetailsToShow.Source.startsWith('http') ? dealDetailsToShow.Source : `http://${dealDetailsToShow.Source}` }
                    target="_blank">
                    { dealDetailsToShow.Source }
                  </a>
                </td>
              </tr>
              <tr>
                <td>Industry</td>
                <td>{ dealDetailsToShow.Industry }</td>
              </tr>
            </tbody>
          </Table> }
        </Modal.Body>
      </Modal>
      <Modal
        show={ confirmUnlockDealId !== null }
        onHide={ handleConfirmUnlockModalClose }
        backdrop="static"
        keyboard={ false }
      >
        <Modal.Header closeButton>
          <Modal.Title>Unlock deal</Modal.Title>
        </Modal.Header>
        { typeof creditsAmount === 'number' && creditsAmount > 0 ? <>
          <Modal.Body>
            { unlockDealAlerts.length > 0 && <div>
              { unlockDealAlerts }
            </div> }
            { creditsAmount !== null && <span>You have { creditsAmount } credits. </span> }
            <span>Are you sure want to unlock deal?</span>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="outline-primary" onClick={ handleConfirmUnlockModalClose }>No</Button>
          <Button variant="outline-danger" onClick={ handleUnlockDeal }>Yes</Button>
          </Modal.Footer>
        </> : <>
          <Modal.Body>
            You have no credits to unlock deal.
          </Modal.Body>
        </> }
      </Modal>
    </div>
  );
}

export default DealsTable;