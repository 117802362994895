import { loadStripe } from "@stripe/stripe-js";
import { authFetch } from "../auth";
import config from '../config.json';
import { SUBSCRIPTION_TYPE_1, SUBSCRIPTION_TYPE_2, SUBSCRIPTION_TYPE_3 } from "./constants";

let actionInProgress = false;

export const parseAfterLoginAction = () => {
    if (actionInProgress) return;
    actionInProgress = true;
    const afterLoginAction = localStorage.getItem('dZ-afterLoginAction');
    // return;
    if (!afterLoginAction) return; // No action in localStorage
    const [action, payload] = afterLoginAction.split('::');

    switch (action) {
        case 'subscription':
            doSubscription(payload);
            break;
        default:
            localStorage.removeItem('dZ-afterLoginAction')
        break;
    }
}

export const doSubscription = async (subscriptionType, products=null) => {
    console.log('Doing subscription')

    // Fetch app configs
    const { data: appConfigs } = await authFetch({
        url: config.SERVER_URL + '/api/configs'
    })

    if (!products) {
        const response = await authFetch({
            url: config.SERVER_URL + '/api/products'
        });
        products = response.data
    }
  

    let price_id;
    let mode;
    for(let product of products) {
        if (product.name === 'Credits' && subscriptionType === SUBSCRIPTION_TYPE_1) {
           price_id = product.prices.find(e => e.quantity === 1 )?.id;
           mode = 'one_time';
           break;
        } else if (product.name === "Membership" && subscriptionType === SUBSCRIPTION_TYPE_2) {
            price_id = product.prices.find(e => e.recurring?.interval === 'month' )?.id;
           mode = "recurring";
            break;
        } else if (product.name === "Membership" && subscriptionType === SUBSCRIPTION_TYPE_3) {
            price_id = product.prices.find(e => e.recurring?.interval === 'year' )?.id;
            mode ="recurring";
            break;
        }
    }

    console.log('App configs ', appConfigs, price_id);
    let response = await authFetch({
        method: 'POST',
        url: config.SERVER_URL + '/api/payments/checkout-sessions',
        data: {
            "price_id": price_id,
            "mode": mode,
        }
    });
    if (response.data && appConfigs && appConfigs.public_key) {
        let sessionId = response.data.session_id;
        let publicKey = appConfigs.public_key;
        const stripe = await loadStripe(publicKey);
        stripe.redirectToCheckout({ sessionId });
    }
    actionInProgress = false;
}