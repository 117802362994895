import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { useState, useEffect } from 'react';
import DashboardPage from '../containers/DashboardPage';
import UserAvatar from '../components/UserAvatar';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
import config from '../config.json';
import { authFetch } from '../auth';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import Modal from 'react-bootstrap/Modal';

import IconWallet from '../img/wallet.svg';
import IconTicket from '../img/ticket.svg';
import IconInvoice from '../img/invoice.svg';

function Profile() {
  const history = useHistory();
  const [profileData, setProfileData] = useState(null);
  const [profileDataStatus, setProfileDataStatus] = useState(null);
  const [activeTab, setActiveTab] = useState('billing-history');
  const [totalCredits, setTotalCredits] = useState(null);
  const [billingHistory, setBillingHistory] = useState(null);
  const [creditHistory, setCreditHistory] = useState(null);
  const [products, setProducts] = useState(null);
  const [appConfigs, setAppConfigs] = useState(null);
  const [creditsProduct, setCreditsProduct] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const handleTabNav = eventKey => {
    setActiveTab(eventKey);
  }
  useEffect(() => {
    // Fetch profile data
    authFetch({
      url: config.SERVER_URL + '/api/auth/profile'
    }).then(response => {
      setProfileData(response.data);
      setProfileDataStatus(response.status);
    }).catch(error => {
      if (error.response) {
        setProfileDataStatus(error.response.status);
      } else {
        setProfileDataStatus(400);
      }
    });

    // Fetch credits amount data
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/auth/credits'
    }).then(response => {
      let creditsData = response.data;

      if (creditsData.subscription && creditsData.subscription.status === 'active' && creditsData.subscription.interval) {
        let subscriptionPlanName = creditsData.subscription.interval + 'ly';
        subscriptionPlanName = subscriptionPlanName[0].toUpperCase() + subscriptionPlanName.slice(1);
        setSubscriptionPlan(subscriptionPlanName);
      }

      if (response.data && response.data.total_credits !== undefined) {
        setTotalCredits(response.data.total_credits);
      }
    }).catch(() => {
      setTotalCredits(0);
    });

    // Fetch billing history
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/payments/billing-histories'
    }).then(response => {
      setBillingHistory(response.data);
    }).catch(() => {
      setBillingHistory([]);
    });

    // Fetch credit history
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/payments/credit-histories'
    }).then(response => {
      setCreditHistory(response.data);
    }).catch(() => {
      setCreditHistory([]);
    });

    // Fetch subscription plans and other products
    authFetch({
      url: config.SERVER_URL + '/api/products'
    }).then(response => {
      setProducts(response.data);
    });

    // Fetch app configs
    authFetch({
      url: config.SERVER_URL + '/api/configs'
    }).then(response => {
      setAppConfigs(response.data);
    });
  }, []);

  const handleAddCredits = async () => {
    if (!products) {
      return;
    }

    let creditsProduct = products.filter(p => p.name === 'Credits').pop();

    if (!creditsProduct) {
      return;
    }

    setCreditsProduct(creditsProduct);

    // let response = await authFetch({
    //   method: 'POST',
    //   url: config.SERVER_URL + '/api/payments/checkout-sessions',
    //   data: {
    //     "price_id": creditsProduct.prices.pop().id,
    //     "mode": 'one_time',
    //   }
    // });

    // if (response.data && appConfigs && appConfigs.public_key) {
    //   let sessionId = response.data.session_id;
    //   let publicKey = appConfigs.public_key;
    //   const stripe = await loadStripe(publicKey);
    //   stripe.redirectToCheckout({ sessionId });
    // }
  }

  const handleChooseCreditsPrice = async choosedPrice => {
    let response = await authFetch({
      method: 'POST',
      url: config.SERVER_URL + '/api/payments/checkout-sessions',
      data: {
        "price_id": choosedPrice.id,
        "mode": 'one_time',
      }
    });
    console.log('Choosed price', choosedPrice.id)
    if (response.data && appConfigs && appConfigs.public_key) {
      let sessionId = response.data.session_id;
      let publicKey = appConfigs.public_key;
      const stripe = await loadStripe(publicKey);
      stripe.redirectToCheckout({ sessionId });
    }
  }

  const handleCancelSubscription = () => {
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/auth/credits'
    }).then(response => {
      let creditsData = response.data;

      if (creditsData.subscription && creditsData.subscription.status === 'active' && creditsData.subscription.interval) {
        let subscriptionPlanName = creditsData.subscription.interval + 'ly';
        subscriptionPlanName = subscriptionPlanName[0].toUpperCase() + subscriptionPlanName.slice(1);
        setSubscriptionPlan(subscriptionPlanName);
      } else {
        setSubscriptionPlan(null);
      }
    });
  }

  return (
    <DashboardPage>
      <div className="container-fluid">
        <h2 className="section-title">Your Profile</h2>
      </div>
      <section id="profile-cards">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-4 mb-4 mb-xl-0">
              <Card className="h-100 profile-card card-user-profile">
                <Card.Body>
                  { profileData === null ? <>
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        {
                          profileDataStatus === 403
                          ? <Alert variant="primary"> Please, <Link to="/verify-account">verify your account.</Link></Alert>
                          : <Spinner animation="grow" variant="primary" />
                        }
                      </div>
                    </> : <>
                    <div className="profile-info">
                      <UserAvatar size={ 80 } onEdit={ () => { history.push('/settings') } } avatar={ profileData.profile_image } />
                      <h3 className="profile-name profile-card-title">{ `${profileData.first_name} ${profileData.last_name}` }</h3>
                      { profileData.company_name
                        && profileData.designation
                        && <div className="profile-bio">{profileData.company_name} - {profileData.designation}</div>
                      }
                    </div>
                    <div className="profile-card-contact-info">
                      <ul className="contact-info-list">
                        {
                          profileData.user_address
                          && profileData.user_address.address1
                          && <li className="address">{profileData.user_address.address1}, {profileData.user_address.city}, {profileData.user_address.country}</li>
                        }
                        {
                          profileData.user_address
                          && profileData.user_address.address2
                          && <li className="address">{profileData.user_address.address2}, {profileData.user_address.city}, {profileData.user_address.country}</li>
                        }
                        {
                          profileData.phone_number
                          && profileData.phone_number
                          && <li className="phone">{profileData.phone_number}</li>
                        }
                      </ul>
                    </div>
                  </> }
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
              <Card className="h-100 profile-card card-balance">
                <Card.Body className="h-100 d-flex flex-column align-items-center">
                  { totalCredits !== null ? <>
                    <img src={ IconWallet } alt="Wallet" />
                    <h3 className="profile-card-title">Current Balance</h3>
                    { <div className="current-balance">
                      { totalCredits } credits
                    </div> }
                    {
                      creditsProduct === null ? <>
                        <Button className="mt-auto" variant="outline-primary" onClick={ handleAddCredits }>Add Credits</Button>
                      </> : <>
                        <div className="btn-group-vertical mt-auto w-100" role="group" aria-label="Basic outlined example">
                          { creditsProduct.prices.reverse().map(price => {
                            let noun = price.quantity === 1 ? 'Credit' : 'Credits';
                            let onClick = () => handleChooseCreditsPrice(price);
                            return  <Button key={price.id} variant="outline-primary" onClick={ onClick }>{ price.quantity } { noun }</Button>
                          }) }
                        </div>
                      </>
                    }
                  </> : <>
                    <Spinner animation="grow" variant="primary" />
                  </> }
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <MembershipProfileCard products={ products } subscriptionPlan={ subscriptionPlan } onCancelSubscription={ handleCancelSubscription } />
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <hr className="my-5" />
      </div>
      <section id="profile-tabs">
        <div className="container-fluid">
          <Nav variant="pills" defaultActiveKey="billing-history" className="my-4 profile-buttons-nav profile-tabs-nav" onSelect={ handleTabNav }>
            <Nav.Item>
              <Nav.Link as={ Button } eventKey="billing-history">Billing History</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={ Button } eventKey="credit-history">Credit History</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="profile-tabs-container">
            { activeTab === 'billing-history' ? (
              <TabBillingHistory data={ billingHistory } />
            ) : activeTab === 'credit-history' ? (
              <TabCreditHistory data={ creditHistory } />
            ) : null }
          </div>
        </div>
      </section>
    </DashboardPage>
  );
}

function TabBillingHistory({ data }) {
  let rows = [];

  if ( data !== null ) {
    rows = data.map((item, idx) => {
      let createdAt = new Date(item.created_at);
      let time = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(createdAt);
      let created = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(createdAt);
      let invoiceLink = item.invoice_pdf;
      const status = <Alert variant="success">{ item.status }</Alert>
      return (
        <tr key={ idx }>
          <td>{ created }</td>
          <td>{ time }</td>
          <td><Alert variant="primary">{ item.mode }</Alert></td>
          <td>{ item.amount }</td>
          <td>{ status }</td>
          <td>{ item.details }</td>
          <td>
            { invoiceLink && <>
              <a href={invoiceLink}>
                <img src={ IconInvoice } alt="Invoice" />
              </a>
            </> }
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="profile-tab tab-billing-history">
      {
        data !== null ? <>
          <div className="table-responsive table-scroll-wrapper">
            <Table className="billing-history-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Mode</th>
                  <th>Amount</th>
                  <th>Credit</th>
                  <th>Details</th>
                  <th>Invoice</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </Table>
          </div>
        </> : <>
          <Spinner animation="grow" variant="primary" />
        </>
      }
    </div>
  );
}

function TabCreditHistory({ data }) {
  let rows = [];

  if (data !== null) {
    rows = data.map(item => {
      let createdAt = new Date(item.created_at);
      let time = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(createdAt);
      let created = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(createdAt);

      return (
        <tr key={ item.id }>
          <td>{ item.id }</td>
          <td>{ item.dealId }</td>
          <td>{ item.credits }</td>
          <td>{ time }</td>
          <td>{ created }</td>
        </tr>
      );
    });
  }

  return (
    <div className="profile-tab tab-credit-history">
      {
        data !== null ? <>
          <div className="table-responsive table-scroll-wrapper">
            <Table className="credit-history-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Deal ID</th>
                  <th>Credits</th>
                  <th>Time</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </Table>
          </div>
        </> : <>
          <Spinner animation="grow" variant="primary" />
        </>
      }
    </div>
  );
}

function MembershipProfileCard({ products, subscriptionPlan, onCancelSubscription }) {
  const [waitingForRedirectToCheckout, setWaitingForRedirectToCheckout] = useState(false);
  const [membershipProduct, setMembershipProduct] = useState(null);
  const [choosedMembershipPlan, setChoosedMembershipPlan] = useState(null);
  const [subscriptionWasCanceled, setSubscriptionWasCanceled] = useState(false);
  const handleMembershipPlanSelect = eventKey => {
    const selectedPlan = membershipProduct.prices.filter(price => price.recurring.interval === eventKey).pop();
    setChoosedMembershipPlan(selectedPlan);
  }
  const handleSubscribe = async () => {
    setWaitingForRedirectToCheckout(true);

    try {
      setSubscribeText('<Spinner animation="grow" variant="primary" />');
      const choosedMembershipPlanId = choosedMembershipPlan.id;

      const publicKey = await authFetch({
        url: config.SERVER_URL + '/api/configs'
      }).then(response => {
        const publicKey = response.data.public_key;
        return publicKey;
      });
      // console.log('Handline plane', choosedMembershipPlanId)
      // return;
      const sessionId = await authFetch({
        url: config.SERVER_URL + '/api/payments/checkout-sessions',
        method: 'POST',
        data: {
          price_id: choosedMembershipPlanId,
          mode: "recurring",
        }
      }).then(response => {
        const sessionId = response.data.session_id;
        return sessionId;
      });

      const stripe = await loadStripe(publicKey);
      stripe.redirectToCheckout({ sessionId });
    } catch(error) {
      console.error(error);
    }

    setWaitingForRedirectToCheckout(false);
  }

  useEffect(() => {
    if (products !== null) {
      const membership = products.filter(product => product.name === 'Membership').pop();
      console.log('member shipt',membership)
      if (membership) {
        setChoosedMembershipPlan(membership.prices[0]);
        setMembershipProduct(membership);
      } else {
        throw new Error('Membership product not found.');
      }
    }
  }, [products]);

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [cancelSubscriptionAlerts, setCancelSubscriptionAlerts] = useState([]);
  const [subscribeText, setSubscribeText] = useState(true);
  const handleCancelSubscriptionClose = () => {
    setShowCancelSubscriptionModal(false);
    setCancelSubscriptionAlerts([]);
    setSubscriptionWasCanceled(null);
  }
  const requestCancelSubscription = () => {
    authFetch({
      url: config.SERVER_URL + '/api/payments/subscription',
      method: 'DELETE'
    }).then(response => {
      if (response.status === 200) {
        setCancelSubscriptionAlerts([
          <Alert variant="success">
            Your subscription was successfully canceled.
          </Alert>
        ]);
        setSubscriptionWasCanceled(true);
        onCancelSubscription();
      } else {
        throw new Error();
      }
    }).catch(error => {
      setCancelSubscriptionAlerts([
        <Alert variant="danger">
          { error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Oops. Something went wrong' }
        </Alert>
      ]);
    });
  }

  return (
    <Card className="h-100 profile-card card-membership">
      <Card.Body className="h-100 d-flex flex-column align-items-center">
        {
          membershipProduct !== null
          ? <>
            <img src={ IconTicket } alt="Ticket" />
            <h3 className="profile-card-title">Unlimited Membership</h3>
            { typeof subscriptionPlan === 'string' && <p>Your current plan: { subscriptionPlan }</p> }
            <div className="membership-plan-switch mb-4">
              <Nav defaultActiveKey={ choosedMembershipPlan.recurring.interval } onSelect={ handleMembershipPlanSelect } className="profile-buttons-nav">
                {
                  membershipProduct.prices.map(price => {
                    const interval = price.recurring.interval;
                    const name = interval[0].toUpperCase() + interval.slice(1) + 'ly';
                    console.log('Interval', interval)
                    return (
                      <Nav.Item key={ interval }>
                        <Nav.Link eventKey={ interval } as={ Button }>{ name } Plan</Nav.Link>
                      </Nav.Item>
                    )
                  })
                }
              </Nav>
            </div>
            { subscribeText == true ? <Button
              className="mt-auto"
              variant="outline-primary"
              onClick={ handleSubscribe }
              disabled={ waitingForRedirectToCheckout }
            >
              Subscribe
            </Button> : <Spinner animation="grow" variant="primary" /> }
            { subscriptionPlan && <Button
              variant="outline-danger"
              size="sm"
              className="close-subscription-btn mt-4"
              onClick={ () => setShowCancelSubscriptionModal(true) }
              >
                Cancel subscription
            </Button> }
          </>
          : <>
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <Spinner animation="grow" variant="primary" />
            </div>
          </>
        }
        <Modal
          show={ showCancelSubscriptionModal }
          onHide={ handleCancelSubscriptionClose }
          backdrop="static"
          keyboard={ false }
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { cancelSubscriptionAlerts.length > 0 && <div>
              { cancelSubscriptionAlerts }
            </div> }
            { !subscriptionWasCanceled && 'Are you sure want to cancel your subscription?' }
          </Modal.Body>
          { !subscriptionWasCanceled && (
            <Modal.Footer>
              <Button variant="outline-primary" onClick={ handleCancelSubscriptionClose }>No</Button>
              <Button variant="outline-danger" onClick={ requestCancelSubscription }>Yes</Button>
            </Modal.Footer>
          ) }
        </Modal>
      </Card.Body>
    </Card>
  );
}

export default Profile;