import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import DashboardPage from '../containers/DashboardPage';
import ValidationFeedback from "../components/ValidationFeedback";
import * as yup from "yup";
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { authFetch } from '../auth';
import config from '../config.json';
import { Link, useHistory } from 'react-router-dom';
import UserAvatar from '../components/UserAvatar';

function Settings() {
  const history = useHistory();
  const [profileData, setProfileData] = useState({ status: null, data: null });
  const [profileUpdateStatus, setProfileUpdateStatus] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const schema = yup.object().shape({
    FirstName: yup.string().required('First Name is required'),
    LastName: yup.string().required('Last Name is required'),
    Address1: yup.string().required('This field may not be blank.'),
    ZipCode: yup.string().required('This field may not be blank.'),
    City: yup.string().required('This field may not be blank.'),
    Country: yup.string().required('This field may not be blank.'),
    PhoneNumber: yup.string().required('This field may not be blank.'),
    ProfilePhoto: yup.mixed(),
  });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = data => {
    const firstName = data.FirstName || '';
    const lastName = data.LastName || '';
    const companyName = data.CompanyName || '';
    const jobPosition = data.JobPosition || '';
    const address1 = data.Address1 || '';
    const address2 = data.Address2 || '';
    const zipCode = data.ZipCode || '';
    const city = data.City || '';
    const country = data.Country || '';
    const phoneNumber = data.PhoneNumber || '';
    const profilePhoto = data.ProfilePhoto || null;

    Promise.all([
      authFetch({
        method: 'PUT',
        url: config.SERVER_URL + '/api/auth/profile',
        data: {
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          designation: jobPosition,
          phone_number: phoneNumber,
          user_address: {
            address1,
            address2,
            zip_code: zipCode,
            city,
            country
          }
        }
      }).then(response => {
        setProfileUpdateStatus(response.status);
      }).catch(error => {
        setProfileUpdateStatus(400);
      }),
      new Promise((resolve, reject) => {
        if (profilePhoto && profilePhoto[0]) {
          const formData = new FormData();
          formData.append('file', profilePhoto[0]);
    
          authFetch({
            method: 'PUT',
            url: config.SERVER_URL + '/api/auth/profile-image',
            data: formData,
          }).then(resolve).catch(reject);
        } else {
          resolve();
        }
      })
    ]).then(() => {
      setTimeout(() => {
        history.push('/profile');
      }, 1000);
    });
  }
  const updateValues = (data) => {
    data.user_address = data.user_address || {};
    let {
      first_name,
      last_name,
      company_name,
      designation,
      phone_number,
      user_address: {
        address1,
        address2,
        zip_code,
        city,
        country,
      }
    } = data;

    setValue('FirstName', first_name);
    setValue('LastName', last_name);
    setValue('CompanyName', company_name);
    setValue('JobPosition', designation);
    setValue('Address1', address1);
    setValue('Address2', address2);
    setValue('ZipCode', zip_code);
    setValue('City', city);
    setValue('Country', country);
    setValue('PhoneNumber', phone_number);
  }
  useEffect(() => {
    authFetch({
      url: config.SERVER_URL + '/api/auth/profile'
    }).then(response => {
      setProfileData({ status: response.status, data: response.data });
      updateValues(response.data);
      setProfilePhoto(response.data.profile_image);
    }).catch(error => {
      const status = error.response ? error.response.status : 400;
      setProfileData({ status, data: null });
    });
  }, []);
  const previewProfilePhoto = (event) => {
    let file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        setProfilePhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <DashboardPage>
      <div className="settings-container">
        <h2 className="section-title">Profile Settings</h2>
        { profileUpdateStatus === 200 && <Alert variant="success">Your profile was successfully updated.</Alert> }
        { profileUpdateStatus >= 400 && <Alert variant="danger">Error.</Alert> }
        { profileData.status === 403 && <Alert variant="primary"> Please, <Link to="/verify-account">verify your account.</Link></Alert> }
        {
          profileData.status !== null
          && profileData.status !== 200
          ? <Alert variant="danger">Failed to load profile data.</Alert>
          : profileData.data
          ? <>
            <Form onSubmit={ handleSubmit(onSubmit) }>
              <UserAvatar className="mb-4" size={ 80 } avatar={ profilePhoto } />
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Profile photo</Form.Label>
                <Form.Control { ...register('ProfilePhoto') } type="file" onChange={ previewProfilePhoto } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('FirstName') }
                  type="text"
                  isInvalid={ errors.FirstName }
                  placeholder="First Name"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.FirstName } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('LastName') }
                  type="text"
                  isInvalid={ errors.FirstName }
                  placeholder="Last Name"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.LastName } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('CompanyName') }
                  type="text"
                  isInvalid={ errors.CompanyName }
                  placeholder="Company Name"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.CompanyName } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('JobPosition') }
                  type="text"
                  isInvalid={ errors.JobPosition }
                  placeholder="Job Position"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.JobPosition } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('PhoneNumber') }
                  type="text"
                  isInvalid={ errors.PhoneNumber }
                  placeholder="Phone Number"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.PhoneNumber } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('Address1') }
                  type="text"
                  isInvalid={ errors.Address1 }
                  placeholder="Address 1"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.Address1 } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('Address2') }
                  type="text"
                  isInvalid={ errors.Address2 }
                  placeholder="Address 2"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.Address2 } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('ZipCode') }
                  type="text"
                  isInvalid={ errors.ZipCode }
                  placeholder="Zip Code"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.ZipCode } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('City') }
                  type="text"
                  isInvalid={ errors.City }
                  placeholder="City"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.City } />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  { ...register('Country') }
                  type="text"
                  isInvalid={ errors.Country }
                  placeholder="Country"
                />
                <ValidationFeedback type="invalid" validationResult={ errors.Country } />
              </Form.Group>
              <Button block type="submit">Save Changes</Button>
            </Form>
          </>
          : <>
            <Spinner animation="grow" variant="primary" />
          </>
        }
        
      </div>
    </DashboardPage>
  );
}

export default Settings;