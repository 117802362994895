import './style.scss';

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import Cancel from "./routes/Cancel";
import Chat from "./routes/Chat";
import Contacts from "./routes/Contacts";
import Dashboard from "./routes/Dashboard";
import { Helmet } from "react-helmet";
import Home from './routes/Home';
import MyDeals from "./routes/MyDeals";
import Products from './routes/Products';
import Profile from "./routes/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./routes/Settings";
import Success from "./routes/Success";
import Users from "./routes/Users"

function App() {
  return (
    <>
    <Helmet>
      <meta name="description" content="Your trusted originator of Micro-PE, small businesses, and Lower Middle Market Companies" />
    </Helmet>
      <Router>
        <Switch>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/contact">
            <Contacts />
          </Route>
          <ProtectedRoute path="/profile">
            <Profile />
          </ProtectedRoute>
          <ProtectedRoute path="/chat">
            <Chat />
          </ProtectedRoute>
          <ProtectedRoute path="/dashboard">
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute path="/my-deals">
            <MyDeals />
          </ProtectedRoute>
          <ProtectedRoute path="/settings">
            <Settings />
          </ProtectedRoute>
          <ProtectedRoute path="/users" checkPermission={true}>
            <Users />
          </ProtectedRoute>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/cancel">
            <Cancel />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        { new URLSearchParams(window.location.search).has('confirm-email') && <Redirect to={{ pathname: '/verify-account', search: window.location.search }} /> }
      </Router>
    </>
  );
}

export default App;
