import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Logo from '../components/Logo';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import config from '../config.json';
import Spinner from 'react-bootstrap/Spinner';
import { logIn, useLogInWithGoogle, useLogInWithLinkedin, authFetch } from '../auth';
import ValidationFeedback from './ValidationFeedback';

import GoogleIcon from '../img/google-icon.svg';
import LinkedinIcon from '../img/linkedin-icon.svg';
import { useEffect, useRef } from 'react';
import { parseAfterLoginAction } from '../utils/afterLoginAction';

function LoginModal({ action }) {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(null);
  const history = useHistory();
  const handleForgotPassword = () => setForgotPassword(true);
  const handleOnHide = () => {
    if (!window.location.search.includes('redirecting'))
      history.push('/');
  }
  const handleBack = () => setForgotPassword(false);
  const [googleLoginHref, setGoogleLoginHref] = useState(null);
  const [linkedinLoginHref, setLinkedinLoginHref] = useState(null);
  const logInWithGoogleStatus = useLogInWithGoogle();
  const logInWithLinkedinStatus = useLogInWithLinkedin();
  
  let waitingForAfterLoginAction = false;

  if (logInWithGoogleStatus === 200 || logInWithLinkedinStatus === 200) {
    let afterLoginAction = localStorage.getItem('dZ-afterLoginAction');
    if (afterLoginAction) {
      waitingForAfterLoginAction = true;
      parseAfterLoginAction()
    } else {
      history.replace('/profile');
    }
  }

  useEffect(() => {
    const status = window.location.search.length && window.location.search.includes('code');
    if (!status) {
      axios.get(config.SERVER_URL + '/api/auth/social-login-configs').then(response => {
        if (response.data && response.data.google_client_id) {
          let loginHref = `https://accounts.google.com/o/oauth2/auth?protocol=oauth2&response_type=token&access_type&client_id=${response.data.google_client_id}&redirect_uri=${window.location.origin}/login&scope=email+profile`;
          setGoogleLoginHref(loginHref);
        }
        if (response.data && response.data.linkedin_client_id) {
          // let loginHref = `https://www.linkedin.com/oauth2/v2/authorization?response_type=code&client_id=${response.data.linkedin_client_id}&scope=r_emailaddress r_liteprofile&state=8897239179ramya&redirect_uri=https://dealzone.us`;
          let loginHref = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_emailaddress,r_liteprofile&client_id=${response.data.linkedin_client_id}&redirect_uri=${response.data.linkedin_redirect_uri}`;
          setLinkedinLoginHref(loginHref);
        }
      });
    }
  }, []);

  const AlertItem = waitingForAfterLoginAction ? <Alert variant='primary'>Loading ...</Alert> :  <>{(logInWithGoogleStatus === 1 || logInWithLinkedinStatus === 1) && <Alert variant='primary'>Please wait, validating credentials ...</Alert>}</>
  const BadReqAlertItem = <>{(logInWithGoogleStatus === 400 || logInWithLinkedinStatus === 400) && <Alert variant='primary'>{window.history.pushState('', '', '/login')}Please make sure your internet is working & try again.</Alert>}</>

  return (
    <Modal show={true} onHide={handleOnHide} className="login-modal">
      <Modal.Header closeButton>
        {forgotPassword && <Button variant="link" onClick={handleBack} className="p-0">Back</Button>}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        <Logo color="blue" variant="vertical" className="d-block mb-4" />
        <div className="w-100 mt-4">
          {logInWithGoogleStatus === 400 ? BadReqAlertItem : AlertItem}
          {(() => {
            switch (action) {
              case 'reset-password':
                return <ResetPasswordForm />;
              case 'login':
                if (!window.location.search.includes("redirecting") && !window.location.href.includes("access_token"))
                  return <TabSignIn forgotPassword={forgotPassword} handleForgotPassword={handleForgotPassword} />;
                else
                  return null
              case 'signup':
                return <TabSignUp status={signUpStatus} onStatusChange={setSignUpStatus} />;
              case 'verify-account':
                return <TabVerifyAccount />
              default:
                return null;
            }
          })()}
        </div>
      </Modal.Body>
      <Modal.Footer className="py-4 justify-content-start">
        <div className="social-login-buttons w-100">
          {googleLoginHref && !window.location.search.includes('redirecting')
            && !window.location.href.includes('access_token') && (
            <Button
              className="social-login-google mb-4"
              variant="outline"
              block
              href={googleLoginHref}
            >
              <img src={GoogleIcon} alt="Google" className="mr-3" /><span>Log In With Google</span>
            </Button>
          ) }
          { linkedinLoginHref && !window.location.search.includes('redirecting')
            && !window.location.href.includes('access_token') && (
            <Button
              className="social-login-linkedin"
              variant="outline"
              block
              href={ linkedinLoginHref }
            >
              <img src={ LinkedinIcon } alt="Linkedin" className="mr-3"/><span>Log In With Linkedin</span>
            </Button>
          ) }
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function TabSignIn({ handleForgotPassword, forgotPassword }) {
  const { register, handleSubmit } = useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [status, setStatus] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const onSubmit = data => {
    setShowSpinner(true);
    setShowAlert(true);
    setStatus(null);

    const payload = {
      'password': data.Password
    }
    if (data.Login.search('@') >=0)
      payload['email'] = data.Login;
    else
      payload['username'] = data.Login;

    axios.post(
      config.SERVER_URL + '/api/auth/login',
      payload,
    ).then(response => {
      setStatus(response.status);
      logIn(response.data.token);

      if (location.state && location.state.redirectAfterLoginTo) {
        let afterLoginAction = localStorage.getItem('dZ-afterLoginAction');
        if (afterLoginAction) {
          parseAfterLoginAction()
        } else {
          history.replace(location.state.redirectAfterLoginTo);
        }
      } else if (response.data.email_verified === false) {
        history.push('/verify-account');
      } else {
        let afterLoginAction = localStorage.getItem('dZ-afterLoginAction');
        if (afterLoginAction) {
          parseAfterLoginAction()
        } else {
          history.push('/dashboard');
        }
      }
    }).catch(error => {
      if (error.response) {
        setStatus(error.response.status);
        setShowAlert(Object.values(error.response.data));
      }
    }).finally(() => {
      setShowSpinner(false);
    });
  }

  return (
    <div className="tab-sign-in">
      {forgotPassword ? (
        <ForgotPasswordForm />
      ) : (
        <>
          {status === 400 && <Alert show={showAlert} dismissible onClose={() => setShowAlert(false)} variant='danger'>Please write appropriate username or email.</Alert>}
          {status === 401 && <Alert show={showAlert} dismissible onClose={() => setShowAlert(false)} variant='danger'>{showAlert}</Alert>}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Control type="text" placeholder="Username or email" {...register('Login')} />
            </Form.Group>
            <Form.Group>
              <Form.Control type="password" placeholder="Password" {...register('Password')} />
            </Form.Group>
            <Row>
              <Col className="text-left">
                <Button variant="link" onClick={handleForgotPassword} className="py-0 px-0">Forgot Password?</Button>
              </Col>
            </Row>
            <Button type="submit" block className="my-3">
              <span>Log In</span>
              {showSpinner && <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-2"
              />}
            </Button>
            <div>Don't have an account? <a href="/signup">Sign Up</a></div>
          </Form>
        </>
      )}
    </div>
  );
}

function TabSignUp({ status, onStatusChange }) {
  const [showAlert, setShowAlert] = useState(status !== null);
  const [showAlertMsg, setShowAlertMsg] = useState(null);
  const history = useHistory();
  const schema = yup.object().shape({
    FirstName: yup.string().required('First Name is required'),
    LastName: yup.string().required('Last Name is required'),
    Email: yup.string().email().required('Email is required'),
    Login: yup.string().matches(/[a-z0-9-_\.]/i, { excludeEmptyString: true, message: 'Invalid login' }).required(),
    Password: yup.string().required('Password is required'),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = (data) => {
    const payload = {
      'username': data.Login,
      'password': data.Password,
      'email': data.Email,
      'first_name': data.FirstName,
      'last_name': data.LastName,
    }
    axios.post(
      config.SERVER_URL + '/api/auth/signup',
      payload
    ).then(response => {
      // onStatusChange(response.status);
      // setShowAlert(true);
      const payload = {
        'username': data.Login,
        'password': data.Password
      }

      axios.post(
        config.SERVER_URL + '/api/auth/login',
        payload,
      ).then(response => {
        onStatusChange(response.status);
        logIn(response.data.token);
        history.push('/verify-account');
      });
    }).catch(error => {
      if (error.response) {
        onStatusChange(error.response.status);
        setShowAlert(true);
        setShowAlertMsg(Object.values(error.response.data));
      }
    });
  }

  if (status === 201) {
    setTimeout(() => {
      history.replace('/login');
    }, 2000);
  }

  return (
    <div className="tab-sign-up">
      {(status === 400) && <Alert show={showAlert} dismissible variant='danger' onClose={() => setShowAlert(false)}>{showAlertMsg ? showAlertMsg[0] : null}</Alert>}
      {(status === 201) ? <Alert variant='success'>Account created. Now you're able to <RouterLink to="/login">Log In</RouterLink></Alert> : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="First Name"
              {...register('FirstName')}
              isInvalid={errors.FirstName}
            />
            {errors.FirstName && <Form.Control.Feedback type="invalid">{errors.FirstName.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Last Name"
              {...register('LastName')}
              isInvalid={errors.LastName}
            />
            {errors.LastName && <Form.Control.Feedback type="invalid">{errors.LastName.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Email"
              {...register('Email')}
              isInvalid={errors.Email}
            />
            {errors.Email && <Form.Control.Feedback type="invalid">{errors.Email.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Username"
              {...register('Login')}
              isInvalid={errors.Login}
            />
            {errors.Login && <Form.Control.Feedback type="invalid">{errors.Login.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder="Password"
              {...register('Password')}
              isInvalid={errors.Password}
            />
            {errors.Password && <Form.Control.Feedback type="invalid">{errors.Password.message}</Form.Control.Feedback>}
          </Form.Group>
          <Button block className="my-3" type="submit">Create Account</Button>
          <div>Already Have an Account? <a href="/login">Log In</a></div>
        </Form>
      )}
    </div>
  );
}

function ForgotPasswordForm() {
  const [status, setStatus] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const schema = yup.object().shape({
    Email: yup.string().email().required('Please, enter your email')
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onResetPasswordSubmit = (data) => {
    const email = data.Email;

    setStatus(null);
    setShowAlert(true);

    axios.post(
      config.SERVER_URL + '/api/auth/forgot-password',
      { 'email': email }
    ).then(response => {
      setStatus(response.status);
    }).catch(error => {
      if (error.response) {
        setStatus(error.response.status);
      }
    });
  }

  return (
    <Form onSubmit={handleSubmit(onResetPasswordSubmit)}>
      <h3 className="mb-3">Password Recovery</h3>
      {status === 200 && <Alert variant="success">Reset link was sent to your email.</Alert>}
      {status === 400 && <Alert show={showAlert} dismissible variant='danger' onClose={() => setShowAlert(false)}>Validation error.</Alert>}
      <Form.Group>
        <Form.Control
          {...register('Email')}
          type="email"
          placeholder="Enter your email"
          isInvalid={errors.Email}
        />
        {errors.Email && <Form.Control.Feedback type="invalid">{errors.Email.message}</Form.Control.Feedback>}
      </Form.Group>
      <div>Didn’t recieve the recovery link? <Button type="submit" variant="link" className="p-0">Resend it</Button></div>
      <Button type="submit" block className="my-3">Confirm</Button>
    </Form>
  );
}

function ResetPasswordForm() {
  const token = new URLSearchParams(window.location.search).get('token');
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState(null);
  const schema = yup.object().shape({
    Password: yup.string().required('Please, enter a new password'),
    PasswordConfirm: yup.string()
      .oneOf([yup.ref('Password'), null], 'Passwords must match')
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = async data => {
    if (!token) {
      setStatus(400);
      setShowAlert(true);
      return;
    }
    try {
      setShowAlert(false);
      setStatus(null);
      const response = await axios.post(
        config.SERVER_URL + '/api/auth/reset-password',
        {
          'token': token,
          'password': data.Password
        }
      );
      setStatus(response.status);
      setTimeout(
        () => window.location.href="/login",
        500
      );
    } catch (error) {
      if (error.response) {
        setStatus(error.response.status)
      }
    }

    setShowAlert(true);
  }

  return (
    <>
      <h3 className="text-left w-100 mb-3">Reset password</h3>
      {status === 400 && <Alert show={showAlert} dismissible onClose={() => setShowAlert(false)} variant='danger'>Error.</Alert>}
      {status === 200 && <Alert variant='success'>Password was successfully reset.</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Control
            {...register('Password')}
            type="password"
            placeholder="New password"
            isInvalid={errors.Password}
          />
          <ValidationFeedback type="invalid" validationResult={errors.Password} />
        </Form.Group>
        <Form.Group>
          <Form.Control
            {...register('PasswordConfirm')}
            type="password"
            placeholder="Confirm password"
            isInvalid={errors.PasswordConfirm}
          />
          <ValidationFeedback type="invalid" validationResult={errors.PasswordConfirm} />
        </Form.Group>
        <Button block type="submit">Reset password</Button>
      </Form>
    </>
  );
}

function TabVerifyAccount() {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const codeField = useRef();
  const [status, setStatus] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const handleRequestVerificationCode = () => {
    setVerificationStatus(null);
    setStatus(null);
    authFetch({
      method: 'POST',
      url: config.SERVER_URL + '/api/auth/resend-email-verification'
    }).then(response => {
      setStatus(response.status);
    }).catch(error => {
      error.response && setStatus(error.response.status);
    });
  }

  const verificationCode = new URLSearchParams(window.location.search).get('confirm-email');

  const onSubmit = data => {
    setVerificationStatus(1);
    authFetch({
      method: 'PUT',
      url: config.SERVER_URL + '/api/auth/email-verification',
      data: {
        code: data.Code
      }
    }).then(response => {
      setVerificationStatus(response.status);
    }).catch(error => {
      setVerificationStatus(400);
    });
  }

  useEffect(() => {
    if (typeof verificationCode === 'string' && verificationCode.length) {
      codeField.current.value = verificationCode;
      onSubmit({ Code: verificationCode });
    }
  }, [verificationCode]);

  if (verificationStatus === 200) {
    setTimeout(() => {
      let afterLoginAction = localStorage.getItem('dZ-afterLoginAction');
      if (afterLoginAction) {
        parseAfterLoginAction()
      } else {
        history.replace('/dashboard');
      }
    }, 2000);
  }

  return (
    <>
      <h3 className="mb-3">Verify account</h3>
      {status === 201 && <Alert variant="primary">Please check your email for verification link</Alert>}
      {status >= 400 && <Alert variant="danger">Error</Alert>}
      {verificationStatus === 1 && <Alert variant="primary">Verifying your account...</Alert>}
      {verificationStatus >= 400 && <Alert variant="danger">Invalid verification code.</Alert>}
      {verificationStatus === 200 && <Alert variant='success'>Account successfully verified!</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Control
            {...register('Code')}
            type="text"
            placeholder="Verification code"
            ref={codeField}
          />
        </Form.Group>
        <Button block className="mb-3" type="submit">Verify account</Button>
        <div>Didn’t recieve the link? <Button onClick={handleRequestVerificationCode} variant="link" className="p-0">Resend it</Button></div>
      </Form>
    </>
  );
}

export default LoginModal;