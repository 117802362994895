import { unparse as unparseCsv } from 'papaparse';

export function exportDealsCSV(dealsForExport) {
  let csvContent = "data:text/csv;charset=utf-8,";
  let dataFieldNames = ['Title', 'Location', 'Price', 'Revenue', 'Cash Flow', 'Description', 'URL', 'Source', 'Industry'];

  let sanitizedData = dealsForExport.map(item => {
    dataFieldNames.forEach(name => {
      if (typeof item[name] === 'string') {
        item[name] = item[name].replace(/\#/g, '№');
      }
    });

    return item;
  });

  var unparsedCsv = unparseCsv({
    data: sanitizedData,
    fields: dataFieldNames,
    quotes: true
  });
  csvContent += unparsedCsv;

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var monthStr = month < 10 ? `0${month}` : `${month}`;
  var dayStr = day < 10 ? `0${day}` : `${day}`;
  link.style.display = 'none';
  link.setAttribute("href", encodedUri);
  link.setAttribute("target", '_blank');
  link.setAttribute("download", `Dealzone ${now.getFullYear()}-${monthStr}-${dayStr}.csv`);
  document.body.appendChild(link); // Required for Firefox
  link.click();
}

export function exportUsersCSV(usersForExport) {
  console.log('Users', usersForExport)
  let csvContent = "data:text/csv;charset=utf-8,";
  let dataFieldNames = ['Email', 'Subscription Status'];

  var unparsedCsv = unparseCsv({
    data: usersForExport,
    fields: dataFieldNames,
    quotes: true
  });
  csvContent += unparsedCsv;

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var monthStr = month < 10 ? `0${month}` : `${month}`;
  var dayStr = day < 10 ? `0${day}` : `${day}`;
  link.style.display = 'none';
  link.setAttribute("href", encodedUri);
  link.setAttribute("target", '_blank');
  link.setAttribute("download", `Users ${now.getFullYear()}-${monthStr}-${dayStr}.csv`);
  document.body.appendChild(link); // Required for Firefox
  link.click();
}

export const industryKeywordsMap = {
  'Retail':                           ['Toy', 'Garden Center', 'Multi-Unit', 'Multi-Unit &! Food', 'Shop', 'Multi Unit', 'Retailer', 'Apparel', 'Gas Station', 'C- Store', 'Retail', 'Store', 'Stores'],
  'Education':                        ['Education', 'Preschool', 'Child Care', 'Schools'],
  'Healthcare':                       ['Senior Care', 'Clinic', 'Medical Research', 'Durable Medical Equipment', 'Pain Clinics', 'Chiropractic', 'Therapy', 'Pain Center', 'Care Center', 'Surgical Practice', 'Health Agency', 'Medical Clinic', 'Home Care', 'Wellness Center', 'Drugstore', 'Dental Practice', 'Clinics', 'Medical Practice', 'Pharmacy', 'Dentists'],
  'Real Estate':                      ['Estate', 'Property'],
  'Manufacturing':                    ['Plant', 'Mfg', 'Trailer Construction', 'Fabrication', 'Manuf', 'Meat Processing', 'Machining', 'Mfr', 'Machine', 'Manufacturer', 'Mills', 'Manufacturing', 'Precision'],
  'Consumer Goods':                   ['Cannabis Dispensaries', 'Magazine', 'Bridal', 'Luxury Good'],
  'Food & Beverage':                  ['Diner', 'Entertainment Park', 'Bakery', 'Vacation Rental', 'Cafe', 'Drive-Thru', 'Bed and Breakfast', 'Event Business', 'Resort', 'Supermarket', 'Grocery Store', 'Food Multiunit', 'Nighlife', 'Movie Theater', 'Bistro', 'Cantina', 'Cuisine', 'Distillery', 'Pizzeria', 'Franchise', 'Restaurant', 'Jump Park', 'Restaurants', 'Hotel', 'Food Services', 'Recreation'],
  'Business Services':                ['Waste Management', 'Equipment Sales', 'Pool', 'Repair Company', 'Marketing Agency', 'Remodeling', 'Design Company', 'Manufacturer', 'Subcontractor', 'Accounting Firm', 'Body Repair', 'Auto Service', 'Agency', 'Dental Business', 'Cabinetmaker', 'Installer', 'Roofing', 'Builder', 'Solar', 'Equipment Rental', 'Electrical Company', 'Equipment Repair', 'Drywall', 'Digging Business', 'Installation', 'Designs', 'Provider', 'Excavation', 'Human Resource Firm', 'Remodeling', 'Air Conditioning Business', 'Cleaning Services', 'Coatings', 'Safety Service', 'Homebuilder', 'Remodeling Business', 'Service Company', 'Excavation', 'Fence  Company', 'Asphalt', 'Aviation Charter', 'Commercial Roofing', 'Concrete Company', 'Landscaping', 'Plumbing Service', 'Cabinet Company', 'Electrical Service Provider', 'Design', 'Fabricator', 'Waste Management', 'Automotive Repair', 'Services Company', 'Accounting Firm', 'Printing Business', 'Glass Company', 'Car Wash', 'Contractor', 'Consulting', 'Fill Dirt', 'Landscape', 'Housing', 'Pest Control', 'Landscape', 'Restoration', 'Plumbing', 'Security Systems', 'Service Business', 'Window & Door Company', 'Carpentry', 'Garage Door', 'Saftey Training', 'Consulting', 'Bus Company', 'Towing', 'Business Service', 'Landscaping', 'Concrete', 'HVAC', 'Property Mngmt', 'Day Care', 'Carwash', 'Contracting', 'Crane', 'Law Firm', 'Scaffolding', 'Staffing', 'Dry Cleaner', 'Trade Services', 'Flooring', 'Rental Company', 'Car Wash', 'Investigation ', 'Services', 'IT', 'Repair', 'Maintenance', 'Recycling', 'Contractors', 'Finishing Company', 'Service Provider', 'Roofing Company', 'Landfill', 'Engineering', 'Painting', 'Legal Services', 'Videography', 'Construction', 'Fence', 'Cleaning Business', 'Contractor'],
  'Utilities & Energy':               ['Fuel Company', 'Waste Management', 'Utility', 'Oil Services'],
  'Transportation & Logistics':       ['Trucking Company', 'Fed Ex', 'Fedex Route', 'Fedex', 'Transportation', 'Logistics', 'Ambulette', 'Trucking'],
  'Wholesale Trade/Distribution':     ['Trucking', 'Distributor', 'Dealer', 'Distribution', 'Dealership', 'Wholesaler', 'Wholesalers', 'Supplier', 'Dealers', 'Supply', 'Wholesale'],
  'Financial Services/Insurance':     ['Insurance', 'Insurance Administration','Mortgage Company','Brokerage', 'Insurance Agency', 'Financial Services'],
  'Technology, SaaS, and E-Commerce': ['Web-based', 'Telecommunications', 'It Company', 'Marketing', 'Digital Marketing', 'Internet', 'Software', 'E Commerce', 'Online', 'SEO', 'Ecommerce', 'Saas'],
}

export function setDataEntryIndustriesForSearch(dataEntry) {
  const dataEntryIndustry = typeof dataEntry['Industry'] === 'string' ? dataEntry['Industry'] : '';
  const dataEntryTitle = typeof dataEntry['Title'] === 'string' ? dataEntry['Title'] : '';
  const compareIndustry = dataEntryIndustry.toLowerCase().trim();
  const compareTitle = dataEntryTitle.toLowerCase().trim();

  let searchIndustries = [];

  for (let industry in industryKeywordsMap) {
    const keywords = industryKeywordsMap[industry];

    for (let keyword of keywords) {
      const compareKeyword = keyword.toLowerCase();

      if (
        (compareIndustry.length > 0 && compareIndustry.includes(compareKeyword))
        || (compareTitle.length > 0 && compareTitle.includes(compareKeyword))
      ) {
        !searchIndustries.includes(industry) && searchIndustries.push(industry);
      } else if (
        typeof dataEntry['Title'] !== 'string' && dataEntry['Industry'] !== 'string'
        && typeof dataEntry['Description'] === 'string' && !searchIndustries.includes(industry)
      ) {
        // keywords lookup in a description if title and industry are empty
        const compareDescription = dataEntry['Description'].toLocaleLowerCase();
        compareDescription.includes(compareKeyword) && searchIndustries.push(industry);
      }
    }
  }

  dataEntry['search_industries'] = searchIndustries;

  return dataEntry;
}

export const locationKeywordsMap = {
  'Alabama': ['Alabama'],
  'Alaska': ['Alaska'],
  'Arizona': ['Arizona'],
  'Arkansas': ['Arkansas'],
  'California': ['California'],
  'Colorado': ['Colorado'],
  'Connecticut': ['Connecticut'],
  'Delaware': ['Delaware'],
  'Florida': ['Florida'],
  'Georgia': ['Georgia'],
  'Guam': ['Guam'],
  'Hawaii': ['Hawaii'],
  'Idaho': ['Idaho'],
  'Illinois': ['Illinois'],
  'Indiana': ['Indiana'],
  'Iowa': ['Iowa'],
  'Kansas': ['Kansas'],
  'Kentucky': ['Kentucky'],
  'Louisiana': ['Louisiana'],
  'Maine': ['Maine'],
  'Maryland': ['Maryland'],
  'Massachusetts': ['Massachusetts'],
  'Michigan': ['Michigan'],
  'Minnesota': ['Minnesota'],
  'Mississippi': ['Mississippi'],
  'Missouri': ['Missouri'],
  'Montana': ['Montana'],
  'Nebraska': ['Nebraska'],
  'Nevada': ['Nevada'],
  'New Hampshire': ['New Hampshire'],
  'New Jersey': ['New Jersey'],
  'New Mexico': ['New Mexico'],
  'New York': ['New York'],
  'North Carolina': ['North Carolina'],
  'North Dakota': ['North Dakota'],
  'Ohio': ['Ohio'],
  'Oklahoma': ['Oklahoma'],
  'Oregon': ['Oregon'],
  'Pennsylvania': ['Pennsylvania'],
  'Puerto Rico': ['Puerto Rico'],
  'Rhode Island': ['Rhode Island'],
  'South Carolina': ['South Carolina'],
  'South Dakota': ['South Dakota'],
  'Tennessee': ['Tennessee'],
  'Texas': ['Texas'],
  'Utah': ['Utah'],
  'Vermont': ['Vermont'],
  'Virginia': ['Virginia'],
  'Virgin Islands': ['Virgin Islands'],
  'Washington': ['Washington'],
  'West Virginia': ['West Virginia'],
  'Washington': ['Washington'],
  'Wisconsin': ['Wisconsin'],
  'Wyoming': ['Wyoming'],
  'Dist. of Columbia': ['Dist. of Columbia'],
  'Undisclosed': ['Undisclosed']
}

export const shortLocationKeywordsMap = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'Dist. of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'VI': 'Virgin Islands',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export function setDataEntryLocationsForSearch(dataEntry) {
  const dataEntryLocation = dataEntry['Location'];

  if (typeof dataEntryLocation === 'string') {
    let searchLocations = [];

    Object.keys(locationKeywordsMap).forEach(location => {
      const keywords = locationKeywordsMap[location];

      keywords.forEach(keyword => {
        const compareLocation = dataEntryLocation.toLowerCase().trim();
        const compareKeyword = keyword.toLowerCase();

        if (compareLocation.length > 0 && compareLocation.includes(compareKeyword)) {
          searchLocations.push(location);
        }
      })
    });

    dataEntry['search_locations'] = searchLocations;
  }

  return dataEntry;
}

export function moneyStringToNumber(moneyString) {
  let dashIndex = moneyString.lastIndexOf('-');
  if (dashIndex >= 0){
    return "money is range value"
  }
  
  let moneyStringAsNumber = 0
  moneyString = moneyString.replace('$', '').replace(/,/g, '');
  if (moneyString.includes("M") || moneyString.includes("K")) {
    let dotIndex = moneyString.indexOf('.');
    let decimal_length =  dotIndex>0 ? moneyString.substring(dotIndex+1).replace(/M/g, '').replace(/K/g, '').length : 0
    moneyString = moneyString.replace(/M/g, '000000');
  	moneyString = moneyString.replace(/K/g, '000');
   	moneyString = moneyString.replace(/\D/g, '');
  	moneyStringAsNumber = parseInt(moneyString, 10);
  	for (var i=0; i < decimal_length; i++) {
      moneyStringAsNumber = moneyStringAsNumber/10
    }
  }
  else {
      moneyStringAsNumber = parseInt(moneyString, 10);
  }
  
  return moneyStringAsNumber;
}

export function TableSortingButton({ fieldName, onSort, sortConfig }) {
  const acsActiveClass = sortConfig && sortConfig.fieldName === fieldName && sortConfig.direction === 'ascending' ? 'active' : '';
  const descActiveClass = sortConfig && sortConfig.fieldName === fieldName && sortConfig.direction === 'descending' ? 'active' : '';

  return (
    <div className='table-sort-buttons' style={{
      display: 'inline-flex',
      flexFlow: 'column nowrap',
    }}>
      <button className={ `btn-asc ${acsActiveClass}` } onClick={ () => onSort({ fieldName, direction: 'ascending' }) }></button>
      <button className={ `btn-desc ${descActiveClass}` } onClick={ () => onSort({ fieldName, direction: 'descending' }) }></button>
    </div>
  );
}