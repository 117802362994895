import Card from 'react-bootstrap/Card';

function NoItemsCard({ children }) {
  return (
    <Card className="no-items-card mx-auto">
      <Card.Body>
        { children }
      </Card.Body>
    </Card>
  )
}

export default NoItemsCard;