import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import DashboardPage from '../containers/DashboardPage';
import DealsIcon from '../img/deals.svg';
import DealsTable from '../components/DealsTable';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NoItemsCard from '../containers/DashboardNoItemsCard';
import Pagination from '../components/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import { authFetch } from '../auth';
import config from '../config.json';
import { useHistory } from 'react-router-dom';
import {
  exportDealsCSV,
  industryKeywordsMap,
  locationKeywordsMap,
  moneyStringToNumber,
  setDataEntryIndustriesForSearch,
  setDataEntryLocationsForSearch,
  shortLocationKeywordsMap,
} from '../utils';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

function MyDeals() {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const viewRowsAmounts = [5, 10, 20, 50];
  const [page, setPage] = useState(1);
  const [viewRows, setViewRows] = useState(viewRowsAmounts[0]);
  const [sortConfig, setSortConfig] = useState({});

  const handleSetViewRows = rowsAmount => {
    setViewRows(rowsAmount);
    setPage(1);
  }

  const handleSelectedDealsChange = deals => {
    setSelectedDeals(deals);
  }

  const handleExportTable = () => {
    let dealsForExport = data.filter(deal => selectedDeals.includes(deal.id));
    exportDealsCSV(dealsForExport);
  }

  const postProcessDealsData = allDeals => {
    // Add industries and locations for search
    allDeals = allDeals.map(deal => {
      setDataEntryIndustriesForSearch(deal);
      setDataEntryLocationsForSearch(deal);
      return deal;
    });
    // Change location field
    allDeals = allDeals.map(deal => {
      let dealLocation = deal['Location'];
      let searchLocations = deal['search_locations'];
      let searchIndustries = deal['search_industries'];

      if (dealLocation && searchLocations && searchLocations.length > 0) {
        deal['Location'] = searchLocations.join(', ');
      }

      if (searchIndustries && searchIndustries.length > 0) {
        deal['Industry'] = searchIndustries.join(', ');
      }

      return deal;
    });

    // parse revenue and cash flow to number
    allDeals = allDeals.map(deal => {
      let revenue = deal['Revenue'];
      let cashFlow = deal['Cash Flow'];

      if (typeof revenue === 'string') {
        let revenueAsNumber = moneyStringToNumber(revenue);

        if (typeof revenueAsNumber === 'number' && !isNaN(revenueAsNumber)) {
          deal['revenue_as_number'] = revenueAsNumber;
          deal['Revenue'] = currencyFormat.format(revenueAsNumber);
        }
        else if (revenueAsNumber === 'money is range value') {
          deal['revenue_as_number'] = null;
          deal['Revenue'] = 'Not Disclosed';
        }
      } else if (typeof revenue === 'number') {
        deal['revenue_as_number'] = revenue;
      }

      if (typeof cashFlow === 'string') {
        let cashFlowAsNumber = moneyStringToNumber(cashFlow);

        if (typeof cashFlowAsNumber === 'number' && !isNaN(cashFlowAsNumber)) {
          deal['cash_flow_as_number'] = cashFlowAsNumber;
          deal['Cash Flow'] = currencyFormat.format(cashFlowAsNumber);
        }
        else if (cashFlowAsNumber === 'money is range value') {
          deal['cash_flow_as_number'] = null;
          deal['Cash Flow'] = 'Not Disclosed';
        }
      } else if (typeof cashFlow === 'number') {
        deal['cash_flow_as_number'] = cashFlow;
      }

      return deal;
    });

    // Remove deals with empty industry
    allDeals = allDeals.filter(deal => {
      return typeof deal['Industry'] === 'string' && deal['Industry'].trim().length > 0;
    });
    // Remove other invalid deals
    // allDeals = allDeals.filter(deal => {
    //   if (deal['revenue_as_number'] === undefined || isNaN(deal['revenue_as_number'])) {
    //     return false;
    //   }

    //   if (deal['search_industries'] === undefined || deal['search_industries'].length === 0) {
    //     return false;
    //   }

    //   if (deal['search_locations'] === undefined || deal['search_locations'].length === 0) {
    //     return false;
    //   }

    //   return true;
    // });

    return allDeals;
  }

  useEffect(() => {
    authFetch({
      method: 'GET',
      url: config.SERVER_URL + '/api/unlocked-deals'
    }).then(response => {
      let deals = response.data;

      if (Array.isArray(deals)) {
        deals = deals.map(deal => {
          deal.unlocked = true;
          return deal;
        });
      }

      setData(postProcessDealsData(deals));
    });
  }, []);

  const dataChunks = [];

  if (data) {
    for (let i = 0; i < data.length / viewRows; i++) {
      let startIndex = viewRows * i;
      let endIndex = viewRows * (i + 1);
      let chunk = data.slice(startIndex, endIndex);
      dataChunks.push(chunk);
    }
  }

  return (
    <DashboardPage>
      <div className="container-fluid mb-4">
        <h2 className="section-title mb-0">My Deals</h2>
      </div>
      <div className="container-fluid"> 
        <div className="dashboard-actions">
          <div className="dashboard-action dashboard-action-search">
            <InputGroup>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
              />
            </InputGroup>
          </div>
          <div className="dashboard-action dashboard-action-sort">
            <DropdownButton id="dropdown-basic-button" title={ `${viewRows} rows` } variant="outline-primary">
              { viewRowsAmounts.map(amount => <Dropdown.Item key={ amount } onClick={ () => handleSetViewRows(amount) }>{ `${amount} rows` }</Dropdown.Item>) }
            </DropdownButton>
          </div>
          <div className="dashboard-action dashboard-action-export">
            <Button onClick={ handleExportTable } disabled={ selectedDeals.length === 0 }>Export Selected</Button>
          </div>
        </div>
      </div>
      <section id="deals">
        <div className="container-fluid my-4">
          { data === null ? <>
            <Spinner animation="grow" variant="primary" />
          </> : <>
            { typeof data === 'object' && data.length ? <>
              <DealsTable
                data={ dataChunks[page - 1] }
                allDeals={ data }
                onSelectedChange={ handleSelectedDealsChange }
                onSort={ setSortConfig }
              />
              { dataChunks.length > 1 && <Pagination
                count={ dataChunks.length }
                page={ page }
                onSelect={ page => setPage(page) }
              /> }
            </> : <>
              <NoItemsCard>
                <div className="d-flex flex-column align-items-center">
                  <img src={ DealsIcon } alt="Deals" className="mb-3" />
                  <h3 className="text-center">You have no unlocked deals yet</h3>
                  <Button variant="outline-primary" className="mt-5" onClick={ () => history.push('/dashboard') }>
                    Explore Deals
                  </Button>
                </div>
              </NoItemsCard>
            </> }
          </> }
        </div>
      </section>
    </DashboardPage>
  )
}

export default MyDeals;