function Hero(props) {
  const {
    backgroundImage = '',
    backgroundDarkness = .4,
    title,
    headerComponent = null,
    children,
    className = '',
  } = props;

  const style = {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, ${backgroundDarkness}),rgba(0, 0, 0, ${backgroundDarkness}) ), url(${backgroundImage})`,
  };

  let classNames = ['hero d-flex flex-column wlex-wrap-nowrap', className];
  headerComponent && classNames.push('with-header');
  classNames = classNames.join(' ');

  return (
    <div className={ classNames } style={ style }>
      { headerComponent }
      <div className="hero-content-wrapper container-fluid d-flex flex-column justify-content-center align-items-center">
        <div className="hero-text">
          { title && <h2>{ title }</h2> }
          { children && <div className="hero-content">{ children }</div> }
        </div>
      </div>
    </div>
  );
}

export default Hero;