import Button from 'react-bootstrap/Button';

function ProductCard(props) {
  const { 
    price,
    title,
    buttonText,
    description,
    features,
    buttonColor,
    current = false,
    onBtnClick
  } = props;

  let buttonVariant = current ? 'secondary' : buttonColor === 'white' ? 'light' : 'primary';
  let featuresListItems = features.map((feature, idx) => <li key={`feature-${idx}`}>{ feature }</li>);

  return (
    <div className="product-card h-100 d-flex flex-column">
      <div className="product-card-price">
        { price }
      </div>
      { title && <h2 className="product-card-title">{ title }</h2> }
      { description && <div className="product-card-description">{ description }</div> }
      <div className="line"></div>
      <ul className="product-card-features-list">
        { featuresListItems }
      </ul>
      <Button
        disabled={ current }
        variant={ buttonVariant }
        block
        onClick={ onBtnClick }
      >
        { buttonText }
      </Button>
    </div>
  );
}

export default ProductCard;