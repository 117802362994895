import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

function EmailBlock(props) {
  let className = 'email-block-wrapper';

  if (props.className) {
    className = `${className} ${props.className}`;
  }

  return (
    <div className={ className }>
      <div className="container">
        <div className="email-block">
          <div className="email-and-from">
            <Form>
              <InputGroup>
                <Form.Control
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                />
                <InputGroup.Append>
                  <Button>Submit</Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailBlock;