import Button from 'react-bootstrap/Button';

function LandingBlock(props) {
  const { image, title, link, contentSide = "right", children } = props;

  return (
    <div className={`landing-block content-side-${contentSide} d-flex flex-column align-items-center flex-lg-row py-4`}>
      <div className="landing-block-image-wrapper">
        <img src={ image } alt="Title" />
      </div>
      <div className="landing-block-content-wrapper">
        { title && <h3 className="landing-block-title">{ title }</h3> }
        <div className="landing-block-text">
          { children }
        </div>
        { link && <>
          <div className="landing-block-button">
            <Button href={ link }>Learn More</Button>
          </div>
        </> }
      </div>
    </div>
  );
}

export default LandingBlock;