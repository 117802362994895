import DashboardPage from '../containers/DashboardPage';

function Cancel() {
  return (
    <DashboardPage>
      <p>Picked the wrong subscription? Shop around then come back to pay!</p>
    </DashboardPage>
  );
}

export default Cancel;